import React, {useState, useEffect, Fragment} from 'react';
import jwt_decode from 'jwt-decode';
import axios from '../../axios';
import './BiznesShfrytezuesi.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";
import silhouette from '../../images/imagesss.jpg'

const BiznesShfrytezuesi = () => {
    let history = useHistory();

    const token = window.localStorage.getItem("cd");
    const decode = token ? jwt_decode(token) : null;

    const [userData, setuserData] = useState([]);
    const [isUserEdit, setisUserEdit] = useState(false);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isPasswordEdit, setisPasswordEdit] = useState(false);
    const [passData, setpassData] = useState([]);
    const [passValidation, setPasswordValidation] = useState('');
    const [notifyMsg, setnotifyMsg] = useState('');
    const [pictureSource, setPictureSource] = useState(process.env.REACT_APP_BACK_URL + userData.pic_url)

    const vleratBaze = () => {
        // e.preventDefault();
        const ud = Object.assign({}, userData);
        ud.gender = 'it';
        ud.agerange = '0-0';
        setuserData(ud);
    }

    const fjalkalimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass = e.target.value;
        if (ud.pass.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$')) {
            setPasswordValidation('Te Lutem Ploteso komfirmim fjalëkalimin')
            setpassData(ud);
        } else {
            setPasswordValidation('Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
        }
    };

    const fjalkalimiKonfirmimi = (e) => {
        const ud = Object.assign({}, passData);
        ud.pass2 = e.target.value;
        if (ud.pass2.match('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,25}$') && ud.pass === ud.pass2) {
            setPasswordValidation('Passwordi i ploteson kushtet dhe eshte i njejte')
            setpassData(ud);
        } else {
            setPasswordValidation('Fjalëkalimi duhet te kete minum 8 karaktere nje shkronje te madhe dhe nje te vogel nje karakter special dhe nje numer')
        }
    };

    const emri = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setuserData(ud);
    };

    const mbiemri = (e) => {
        const ud = Object.assign({}, userData);
        ud.surname = e.target.value;
        setuserData(ud);
    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setuserData(ud);
    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setuserData(ud);
    };

    const ndryshoUserin = (e) => {
        e.preventDefault();
        let userEdit = isUserEdit;

        if (userEdit === true) {
            setisUserEdit(false);
        } else {
            setisUserEdit(true);
            vleratBaze();
        }
    }

    const ruajNdryshimet = (e) => {
        e.preventDefault();
        console.log('para se me shku', userData)
        axios.post("/furnitor/user/update/" + decode.id, userData)
            .then((res) => {
                console.log(res.data);
                setisUserEdit(false);
                toast.success('Ndryshimet u ruajtën me sukses!');
            })
            .catch((err) => {
                console.log(err)
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');
                history.push("/kyqu");
            });
    }

    const ruajFjalkalimineRi = (e) => {
        e.preventDefault();
        let pass = passData.pass;
        console.log('passData', {pass});
        axios.post("/furnitor/user/updatepassword/" + decode.id, {pass})
            .then((res) => {
                if (res.status === 401) {
                    window.localStorage.removeItem('cd');
                    toast.error('Authentikimi ju ka skaduar!');
                    history.push("/kyqu");
                } else {
                    console.log('Passwordi u Ndryshua!');
                    setisUserEdit(false);
                    setisPasswordEdit(false);
                    toast.success('Fjalëkalimi u ndryshua me sukses!');
                }
            })
            .catch((err) => {
                toast.error('Diçka shkoi gabim!');
                console.log(err)
            });
    }

    const imageDataChange = (e) => {
        const ud = Object.assign({}, imageData);
        ud.image = e.target.files[0];
        setimageData(ud);
        console.log('fajllimrena', imageData)
    }

    const imazhiToggle = (e) => {
        e.preventDefault();
        let imazhiEdit = ndryshoImazhin;
        if (imazhiEdit) {
            setndryshoImazhin(false);
        } else {
            setndryshoImazhin(true);
        }
    }

    const passwordToggle = (e) => {
        e.preventDefault();
        let paswdEdit = isPasswordEdit;
        if (paswdEdit) {
            setisPasswordEdit(false);
        } else {
            setisPasswordEdit(true);
        }
    }

    const ruajImazhin = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', imageData.image)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            }
        }
        axios.post("/furnitor/user/update-image/" + decode.id, formData, config)
            .then((res) => {
                // console.log(res.data);
                setndryshoImazhin(false);
                getFurnitor();
                toast.success('Imazhi u ndryshua me sukses!');
            })
            .catch((err) => {
                console.log(err)
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');
                history.push("/kyqu");
            });
        // getStore();
        // window.location.reload();
    }

    const getFurnitor = () => {
        axios.get('/furnitor/user/id/' + decode.id)
            .then((res) => {
                console.log('userData', res.data);
                setuserData(res.data);
                console.log('image image image image image', res.data.pic_url)
                setPictureSource(process.env.REACT_APP_BACK_URL + res.data.pic_url)
                // window.localStorage.setItem('name', res.data.name);
                // window.localStorage.setItem('surname', res.data.surname);
            })
            .catch(err => {
                console.log(err);
                // window.localStorage.removeItem('cd');
                // history.push("/kyqu");
            });
    }

    const renderMsg = (msg) => {
        return (
            <div id='notifyMsg' className='notify-msg'>{msg}
                <button onClick={handleMsgClose}><i className='fa fa-close'></i></button>
            </div>
        );
    }

    const handleMsgClose = (e) => {
        e.preventDefault();
        setnotifyMsg('');

    }
    useEffect(() => {
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        getFurnitor();
        // vleratBaze();
    }, []);

// console.log('userdata',userData);
    // console.log('userData', userData);
// console.log('user edit', isUserEdit);


    return (
        <>
            <div className="shfrytezuesi">
                {/* <div className={'titulli'}>  Menaxhimi i Shfrytezuesit</div> */}
                <div className="fjeshil-container">
                    {ndryshoImazhin &&
                    <div className='upload-imazhi'>
                        <form onSubmit={ruajImazhin}>
                            <input type='file' name='file' onChange={imageDataChange}/>
                            <button type='submit' disabled={imageData ? false : true}>Ruaje imazhin e ri!</button>
                        </form>
                    </div>
                    }
                    <div className="row pl-3">
                        <div className="col-md-12">
                            <div className={'row w-100 '}>
                                {notifyMsg != '' && renderMsg(notifyMsg)}
                                <div className={'col-10'}>
                                    <div className="shp_groupform">
                                        <button className='ndrysho-imazhin'
                                                onClick={imazhiToggle}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button>
                                        <div className='user-image-container'>
                                            {/* <img className='user-image' src={'http://localhost:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            {/* <img className='user-image' src={'http://10.10.10.206:8000'+userData.pic_url} alt='userPicture'></img> */}
                                            <img className='user-image'
                                                 src={pictureSource}
                                                 onError={() => {
                                                     setPictureSource(silhouette)
                                                 }}>
                                            </img>
                                        </div>
                                        {!isPasswordEdit &&
                                        <form onSubmit={ruajNdryshimet} className='shp_form'>
                                            <div className='row'>

                                                <h4 className='pl-3 pb-4 w-100 text-left'> Menaxhimi i
                                                    Shfrytëzuesit</h4>
                                                <label className='col-6'>Emri: </label>
                                                <input className='col-6 form-control' defaultValue={userData.name}
                                                       onChange={emri} disabled={!isUserEdit}/>

                                                <label className='col-6'> Mbiemri:</label>
                                                <input className='col-6 form-control' defaultValue={userData.surname}
                                                       onChange={mbiemri} disabled={!isUserEdit}/>

                                                <label className='col-6 '> Email: </label>
                                                <input className='col-6 form-control' defaultValue={userData.email}
                                                       onChange={email} disabled={!isUserEdit}/>

                                                <label className='col-6'> Telefoni: </label>
                                                <input className='col-6 form-control' defaultValue={userData.phone}
                                                       onChange={telefoni} disabled={!isUserEdit}/>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button className='form-control'
                                                            onClick={ndryshoUserin}> {isUserEdit ? 'Largohu' : 'Ndrysho Të Dhënat'} </button>
                                                </div>
                                                <div className='col-6'>
                                                    {isUserEdit &&
                                                    <button type='submit' className='form-control'> Ruaj
                                                        Ndryshimet </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        }
                                        {isPasswordEdit &&
                                        <form className='shp_form'>
                                            <div className='row w-100'>
                                                <h4 className='pl-3 pb-4 w-100 text-left'> Ndrysho Fjalëkalimin e
                                                    Shfrytëzuesit</h4>

                                                <label className='col-6'> Fjalëkalimi:</label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimi}
                                                       required/>

                                                <label className='col-6 '> Konfirmo fjalëkalimin: </label>
                                                <input type='password'
                                                       className='col-6 form-control'
                                                       pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$"
                                                       onChange={fjalkalimiKonfirmimi}
                                                       required/>

                                                {/* <label className='col-6'> Telefoni: </label>
                                        <input className='col-6 form-control'  onChange={''} /> */}
                                            </div>
                                            <p className="text-password">{passValidation}</p>
                                        </form>
                                        }
                                        <form className='row'>
                                            <div className='col-6'>
                                                <button className='form-control'
                                                        onClick={passwordToggle}> {isPasswordEdit ? 'Largohu' : 'Ndrysho Fjalkalimin'} </button>
                                            </div>
                                            {isPasswordEdit &&
                                            <div className='col-6'>
                                                <button className='form-control ' onClick={ruajFjalkalimineRi}
                                                        disabled={passData.pass != null && passData?.pass === passData?.pass2 ? false : true}>  {passData.pass != null && passData?.pass === passData?.pass2 ? 'Ruaj Fjalkalimin' : '...nuk perputhen'} </button>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    {isPasswordEdit &&
                    <p className='pl-3'> * Nese passwordi juaj ndryshohet me sukses ju ridirektoheni ne formen e
                        kyqjes!</p>}
                </div>
            </div>

        </>
    );

}
export default BiznesShfrytezuesi;