import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../images/logo.png';
import axios from '../../axios';
import {

    Redirect

} from 'react-router-dom';
// CSS
import './ShtoStokun.scss';
import jwt_decode from 'jwt-decode';
import Select from "react-select";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

const EditoStokun = (props) => {
    let history = useHistory()
    const [stockData, addstockData] = useState([]);

    const [productData, setproductData] = useState([]);
    const [measurmentsData, setMeasurmentsData] = useState([]);
    const [dateValidationError, setDateValidationError] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)

    // const [errors, addErrors] = useState(false);

//   const token = window.localStorage.getItem('cd');
//   const decode = token ? jwt_decode(token) : null;


    const vleratBaze = () => {
        const ud = Object.assign({}, stockData);
        ud.active = true;
        addstockData(ud);
    }
    const produkti = e => {
        console.log('PRODUKTI INCOMING', e)
        const ud = Object.assign({}, stockData);
        ud.product_id = e.value;
        addstockData(ud);
    }

    const lloji = e => {
        const ud = Object.assign({}, stockData);
        console.log('here lloji ', e.target.value)
        console.log()
        ud.is_payed = e.target.value;
        addstockData(ud);
    }

    const njesiamatese = e => {
        const ud = Object.assign({}, stockData);
        ud.messurment_id = e.target.value;
        addstockData(ud);
    }
    const cope = e => {
        const ud = Object.assign({}, stockData);
        ud.qty = e.target.value;
        addstockData(ud);
    }
    const cmimi = e => {
        const ud = Object.assign({}, stockData);
        ud.price = e.target.value;
        addstockData(ud);
    }
    const dataskadimit = e => {
        const ud = Object.assign({}, stockData);
        ud.expiration_date = e.target.value;
        if (ud.expiration_date.length < 11 && ud.expiration_date.length > 9) {
            addstockData(ud);
            setDateValidationError('')
        } else {
            setDateValidationError('Te lutem shtype nje date valide')
        }
    }

    const hours = e => {
        const ud = Object.assign({}, stockData);
        let hours = Number(e.target.value);
        console.log('hours hours ', typeof (hours))
        console.log(hours)
        ud.stock_timeout = hours;
        addstockData(ud);
    }


    if (stockData.is_payed == false.toString()) {
        stockData.price = '0'
    }


    const cmimiPerDonacion = e => {
        const ud = Object.assign({}, stockData);
        ud.donation_price = e.target.value;
        addstockData(ud);
    }


    const maxQty = e => {
        const ud = Object.assign({}, stockData);
        ud.max_order = e.target.value;
        addstockData(ud);
    }


    const editoStokunn = async (e) => {
        e.preventDefault();
        setIsDisabled(true)
        // console.log('data', stockData);
        // console.log('tikeni', decode);
        // console.log('categories', productData);

        await axios.post('/furnitor/store-stock/update/' + props.match.params.id, stockData)
            .then((res) => {
                console.log(res.data);
                toast.success('Stoku u ndryshua me sukses!');
                history.push("/biznesstoku");
            })
            .catch(err => {
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');
                history.push("/kyqu");
                console.log(err);
            });
        // window.history.back();
        // window.location = '/biznesstoku';
    }



    useEffect(async() => {
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        axios.get('/furnitor/store-stock/' + props.match.params.id)
            .then((res) => {
                async function fetchMyAPI() {
                  await  addstockData(res?.data);
                }
                fetchMyAPI()
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');
                history.push("/kyqu");
            });


        axios.get('/furnitor/store-products/all/' + decode.store)
            .then((res) => {
                // console.log('axios', res.data);
                setproductData(res.data);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');

                history.push("/kyqu");
            });
        axios.get('/general/product-measurments/all/')
            .then((res) => {
                // console.log('axios', res.data);
                setMeasurmentsData(res.data);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                toast.error('Diçka shkoi gabim!');

                history.push("/kyqu");
            })
    }, [props.match.params.id])


    let ar = ''
    let id = ''

    let product = productData?.map((el, i) => {
        console.log(el)
        if (el?._id == stockData?.product_id) {
            ar = el?.name
            id = stockData?._id
            console.log(ar)
        }
        return {value: el?._id, label: el?.name}
    })


    // console.log(product.label)

    console.log('a po ndrron', stockData?.stock_timeout)


    console.log(ar)

    console.log(id)



    // let isFalse = stockData?.is_payed

    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container pl-5 pt-5">
                    <div className="row">
                        <h3 className='p-3 w-100'>Edit Stokun</h3>
                        <div className="col-md-12">
                            <div className="shp_groupform">
                                <form onSubmit={editoStokunn}>
                                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Emri i Produktit"
                        onChange={emri}
                        required
                      /> */}
                                    <label>
                                        <Select onChange={produkti}
                                            // value={ar}
                                            // defaultValue={ar}
                                            // defaultValue={{ value: ar }}
                                                value={{label: ar}}
                                            // getVa/**/lue="hasdh"
                                            //     value={stockData?.product_id}
                                            //     defaultValue={ar}
                                            // value={ar}
                                            // value={"hasdh"}
                                            // inputValue={"asd"}
                                            // defaultValue={stockData.product_id}
                                            // value={stockData.product_id}
                                                options={product}
                                            // key={stockData?.product_id}
                                        >

                                            {/*<option value={null}>Selekto Produktin</option>*/}
                                            {/*{productData?.map(*/}
                                            {/*    (el, index) => {*/}
                                            {/*        return <option key={index} value={el._id}>{el.name}</option>*/}
                                            {/*    }*/}
                                            {/*)}*/}
                                        </Select>
                                    </label>
                                    <label>
                                        <select onChange={lloji} value={stockData.is_payed} className="form-control">
                                            <option value={null}>Selekto Llojin</option>
                                            <option value={true}>Me Pagese</option>
                                            <option value={false}>Donacion</option>
                                        </select>
                                    </label>
                                    <label>
                                        <select onChange={njesiamatese} value={stockData.messurment_id}
                                                className="form-control">
                                            <option value={null}>Selekto Njesine Matese</option>
                                            {measurmentsData?.map((el, index) => {
                                                return <option value={el._id}>{el.name}</option>

                                            })}
                                        </select>
                                    </label>
                                    <input
                                        type='number'
                                        className="form-control s"
                                        step="0.01"
                                        value={stockData?.max_order}
                                        placeholder='Kuantiteti'
                                        onChange={maxQty}
                                        required>
                                    </input>
                                    <input type='number' className="form-control" placeholder='Sasia (numer)'
                                           defaultValue={stockData.qty} onChange={cope}></input>
                                    <label>
                                        {
                                            stockData?.is_payed == 'false' || stockData?.is_payed == false ?
                                                <input type='number' className="form-control" step="0.01"
                                                       placeholder='Çmimi per donaction'
                                                       value={stockData?.donation_price}
                                                       onChange={cmimiPerDonacion} required></input> : ''
                                        }
                                        {
                                            stockData?.is_payed == 'true' || stockData?.is_payed == true ?

                                                <label>
                                                    <input type='number' className="form-control" step="0.01"
                                                           placeholder='Çmimi per cope' disabled={stockData?.is_payed?.toString()}
                                                           defaultValue={stockData.price} onChange={cmimi}></input>
                                                </label>
                                                : ''
                                        }
                                    </label>
                                    <label>
                                        Data e Skadimit
                                        <input type='date'
                                               maxLength="10"
                                            // maxLength="2"
                                               size="10"
                                               max="10"
                                               min="2000-01-01" max="2118-12-31"
                                               className="form-control"
                                               value={stockData?.expiration_date?.slice(0, 10)}
                                               onChange={dataskadimit}></input>
                                    </label>
                                    <label>
                                        Koha e marrjes
                                        <select onChange={hours} value={stockData?.stock_timeout}
                                                className="form-control">
                                            <option value={Number(1)}>1:00</option>
                                            <option value={Number(2)}>2:00</option>
                                            <option value={Number(3)}>3:00</option>
                                            <option value={Number(4)}>4:00</option>
                                            <option value={Number(5)}>5:00</option>
                                            <option value={Number(6)}>6:00</option>
                                            <option value={Number(7)}>7:00</option>
                                            <option value={Number(8)}>8:00</option>
                                            <option value={Number(9)}>9:00</option>
                                            <option value={Number(10)}>10:00</option>
                                            <option value={Number(11)}>11:00</option>
                                            <option value={Number(12)}>12:00</option>
                                        </select>
                                    </label>
                                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Cmimi"
                        required
                      /> */}
                                    {/* <input
                        type="file"
                        name='file'
                        className="form-control"
                        placeholder="picture"
                        onChange={image}
                        required
                      /> */}
                                    <button disabled={isDisabled} type="submit">Edito</button>

                                    {/* store_id: req.params.sid,
        product_id: req.body.product_id,
        is_payed: req.body.is_payed,
        messurment_id: req.body.messurment_id,
        // pic_url: req.body.pic_url,
        qty: req.body.qty,
        product_cat_id: req.body.product_cat_id,
        price: req.body.price,
        expiration_date: req.body.expiration_date, */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditoStokun;
