import React, {useState, useEffect} from 'react';

import axios from '../../axios';
import Select from "react-select";
import {Bar} from 'react-chartjs-2';

// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';

import {useHistory} from "react-router-dom";


const StatsProdukt = (props) => {
    let history = useHistory()
    const [singleProductData, setSingleProductData] = useState('');


    console.log('data incoming', props.match.params.id)

    let singleProductId = props.match.params.id;

    // const getSingleProductData = async () => {
    //     try {
    //         let data = await axios.get(`/furnitor/store-products/statistics/single-product/${singleProductId}`);
    //         setSingleProductData(data.data);
    //         console.log(data.data)
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }


    // console.log('data incoming',props.match.params.id)
    //
    // useEffect(() => {
    //     getSingleProductData();
    // },[])

    let errorMsg = ''

    const [error, setError] = useState('')

    const [stats, setStats] = useState([]);
    const [total, setTotal] = useState([]);

    useEffect(() => {
        axios.get('/furnitor/statistics/single-product/' + props.match.params.id)
            .then((data) => {
                console.log('', data?.data)
                setStats(data?.data);
            })
            .catch(err => {
                console.log(err)
                // window.localStorage.removeItem('cd');

                // history.push("/kyqu");

            });

        axios.get('/furnitor/statistics/single-product/' + props.match.params.id)
            .then((data) => {
                console.log('single', data?.data)
                setTotal(data?.data);

            })
            .catch(err => {
                console.log(err.response.data)
                setError(err.response.data);
                // window.localStorage.removeItem('cd');

                // history.push("/kyqu");

            });
    }, [])

    // const palidhje = stats?.map((el) => el?.total_price,);
    // const emnat = stats?.map((el) => el?.order_date?.slice(0, 10) + ' ' + el?.order_date?.slice(11, 19),);
    // console.log({palidhje})


    console.log('totali', stats?.total_price)


    // console.log('odahsdoaishdoaihsd',a)

    console.log(stats)


    const chartData = {
        labels: ['Shuma e Transakcionit'],
        datasets: [
            {
                label: 'Shuma e Transakcionit',
                data: [stats.total_price],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    //
    //
    // console.log('total price', total?.total_price);
    // console.log('error msg', error)

    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container">
                    <div className="row ">
                        <h3 className='w-100 p-3'> Raportet e
                            Produktit: <b>{total?.name != undefined ? total?.name : error}</b></h3>
                        <div className="row w-100 pt-5 pl-5">
                            <div className='col-4'>
                                <div className='card p-4'>Shuma Totale e Shitur
                                    EUR <br/>{total?.total_price != undefined ? total?.total_price : error}</div>
                            </div>
                            <div className='col-4'>
                                <div className='card p-4'>Sasia Totale e Shitur <br/>{total?.qty}</div>
                            </div>
                        </div>
                        <br/>
                        <div className="col-md-12 p-5">
                            <Bar data={chartData} options={options}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}

export default StatsProdukt;