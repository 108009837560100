import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import EditoProdukt from '../ShtoProdukt/EditoProdukt'
import Category from '../../images/category.png';
import Reports from '../../images/chart-bar-regular.svg';

// CSS
import './BiznesProduktet.scss';
import jwt_decode from "jwt-decode";

const ListaProdukteve = (props) => {


    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;

    console.log(props.data)



    console.log(props.data.store_prod[0].name)


    console.log('data incoming', props.data.url)


    return (
        <>
            <div className={'listimi-produktet '}>
                <div className={'row'}>
                    {/*foto*/}
                    <div className={'col-3'}>
                        <img
                            src={ process.env.REACT_APP_BACK_URL + (props?.data.pic_url != null ? props?.data.pic_url : props?.data?.store_prod[0]?.pic_url) }
                            alt='produkti'
                            className="picture-location"
                        />
                        {/*<img src={'http://10.10.10.206:8000/uploads/stores/2b47519a-b0ce-40f1-a192-8e8c1de669af.jpeg'}*/}
                        {/*     alt='produkti'*/}
                        {/*     className="picture-location"*/}
                        {/*/>*/}
                    </div>
                    {/*content*/}
                    <div className={'col-9'}>
                        <div className={'row'}>
                            {/*name*/}
                            <div className={'col-12'}><h5>{props.data.name}</h5></div>
                            {/*actions*/}
                            <div className={'img-container'}>
                                <div className="iimg-container">
                                    <Link to={"/editoprodukt/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={EditIcon}
                                            className='actions'
                                        />
                                    </Link>
                                    <Link to={"/fshijprodukt/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={DeleteIcon}
                                            className='actions'
                                        />
                                    </Link>
                                    <Link to={"/singleproduct/" + props.data._id}>
                                        <img
                                            alt="delete"
                                            src={Reports}
                                            className='actions'
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={'row category--name '}>{props.data.store_prod[0].name}</div>
                        <div
                            className={props.data.active ? 'row status--name text-success' : 'row status--name text-danger'}>{props.data.active && "Aktiv"} {!props.data.active && 'Pasiv'}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListaProdukteve;
