import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ListaProdukteve from './ListaProdukteve';
import axios from '../../axios';
import jwt_decode from 'jwt-decode';
import ReactPaginate from 'react-paginate';
import Loader from '../Loader/loader';

// CSS
import './BiznesProduktet.scss';
import ListViewProducts from "./ListViewProducts/ListViewProducts";


const BiznesProduktet = (props) => {


    const [productsData, setProductsData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        // getCategories();
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        axios.get('/furnitor/store-products/all/' + decode.store)
            .then((res) => {
                // console.log('axios', res);
                setProductsData(res?.data);
                setFilteredUsers(res?.data)
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            })
    }, []);

    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = productsData?.filter((users) => {
            console.log('users incoming', users)
            return (users?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }


    return (isLoading ? <Loader/> :
            <>
                <div className="biznes_produktet">
                    <div className="fjeshil-container">
                        <div className="row">
                            <div className="col-md-12 biznes_title">
                                <h1>Menaxhimi i Produkteve</h1>
                                <Link to="/shtoprodukt">
                                    <button className="shto-produktet">Shto Produkt</button>
                                </Link>
                                <div className="col-6 col-sm-5  mt-5 pb-3 ">
                                    <input
                                        type="text"
                                        onChange={searcher}
                                        className="form-control search-input"
                                        placeholder="Kërko..."
                                        aria-label="Search"
                                    />
                                </div>
                                {/*<div className="col-md-5">*/}
                                <div className="button-group">
                                    <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-large "></i>
                                    </button>
                                    <button type="button" onClick={() => setComponentToBeRendered('list')}
                                            className="btn btn-primary grid-button">
                                        <i className="fa fa-th-list"></i>
                                    </button>
                                </div>
                                {/*</div>*/}
                            </div>
                            <div className={'row w-100 p-3 pl-1 products-list'}>
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + productsPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <ListaProdukteve data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2 w-100'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-sm"><b>Emri i produktit</b></th>
                                                                        <th className="col-sm"><b>Kategoria</b></th>
                                                                        <th className="col-sm"><b>Statusi</b></th>
                                                                        <th className="col-sm"><b>Imazhi</b></th>
                                                                        <th className="col-sm"><b>Modifiko</b></th>
                                                                        <th className="col-sm"><b>Fshij</b></th>
                                                                        <th className="col-sm"><b>Statistikat</b></th>
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + productsPerList)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListViewProducts
                                                                                        data={filteredUsers}
                                                                                        key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row">
                                <div className="col-12">
                                    {(productsData.length >= productsPerList)
                                        ? <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        /> : ''
                                    }
                                </div>
                            </div>
                            : ''
                    }
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row">
                                <div className="col-12">
                                    {(productsData.length >= productsPerPage) ?
                                        <ReactPaginate
                                            previousLabel={"Mbrapa"}
                                            nextLabel={"Para"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        /> : ''
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </>
    );
};

export default BiznesProduktet;
