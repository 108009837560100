import React, {useState, useEffect, Fragment} from "react";
import jwt_decode from "jwt-decode";
import axios from "../../axios";
import "./BiznesFaqja.scss";
import {MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents} from 'react-leaflet'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import siluette from '../../images/newfoodsafe.png'
import silhouette from "../../images/imagesss.jpg";

const BiznesFaqja = () => {

        const [shopData, setshopData] = useState([]);
        const [stateData, setstateData] = useState([]);
        const [cityData, setcityData] = useState([]);
        const [catData, setcatData] = useState([]);
        const [imageData, setimageData] = useState(null);
        const [ndryshoImazhin, setndryshoImazhin] = useState(false);
        const [isShopEdit, setisShopEdit] = useState(false);
        const [mapState, setMapState] = useState([0, 0]);
        const [center, setCenter] = useState([0, 0]);
        const [located, setLocated] = useState(false);
        const [nrFiscalLengthError, setNrFiscalLengthError] = useState('');
        const [notifyMsg, setnotifyMsg] = useState('');
        const [pictureSource, setPictureSource] = useState(null)

        let map;
        const token = window.localStorage.getItem("cd");
        const decode = token ? jwt_decode(token) : null;

        const getStore = () => {
            // preventDefault();
            axios
                .get("/furnitor/user-store/" + decode.store)
                .then((res) => {
                    // console.log('userData', res.data);
                    setshopData(res.data);
                    let newShop = res.data;
                    newShop.store_description = res.data.description;
                    newShop.store_phone = res.data.phone;
                    setshopData(newShop);
                    setPictureSource(res?.data.pic_url)
                    console.log(res.data)
                    // toast.success('')
                })
                .catch((err) => {
                    console.log(err);
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu"
                    toast.error('Diçka shkoi gabim!');
                });
        }

        // console.log('map state incoming ', mapState[0]);

        const emri = (e) => {
            const ud = Object.assign({}, shopData);
            ud.name = e.target.value;
            setshopData(ud);
        };
        const nrunik = (e) => {
            const ud = Object.assign({}, shopData);
            ud.fiscal_nr = e.target.value;
            setshopData(ud);
        };
        const shteti = (e) => {
            const ud = Object.assign({}, shopData);
            ud.country_id = e.target.value;
            setshopData(ud);
        };
        const qyteti = (e) => {
            const ud = Object.assign({}, shopData);
            ud.city_id = e.target.value;
            setshopData(ud);
        };

        const kategoria = (e) => {
            const ud = Object.assign({}, shopData);
            ud.store_cat = e.target.value;
            setshopData(ud);
        };


        const latLongFunc = async (e) => {
            const ud = Object.assign({}, shopData);
            ud.latitude = e[0];
            ud.longitude = e[1];
            // console.log('inside latitude',ud)
            await setshopData(ud);
        }

        const adresa = (e) => {
            const ud = Object.assign({}, shopData);
            ud.address = e.target.value;
            setshopData(ud);
        }

        const telefoni = (e) => {
            const ud = Object.assign({}, shopData);
            ud.store_phone = e.target.value;
            ud.phone = e.target.value;
            setshopData(ud);
        }

        const orariHapjes = (e) => {
            const ud = Object.assign({}, shopData);
            ud.opening_hour = e.target.value;
            setshopData(ud);
        }

        const orariMbylljes = (e) => {
            const ud = Object.assign({}, shopData);
            ud.closing_hour = e.target.value;
            setshopData(ud);
        }


        const description = (e) => {
            const ud = Object.assign({}, shopData);
            ud.description = e.target.value;
            ud.store_description = e.target.value;
            setshopData(ud);
        }


        // const latitude = async (e) => {
        //     // shopData = {lat = 123, long =123}
        //     console.log('incoming argument lati',e)
        //      const ud = Object.assign({}, shopData);
        //      ud.latitude = e
        //     console.log('inside latitude',ud)
        //     await setshopData(ud);
        //      return;
        // };
        // const longitude = (e) => {
        //     console.log('incoming argument long',e)
        //     const ud = Object.assign({}, shopData);
        //     ud.longitude = e
        //     console.log('inside longitude',ud)
        //     setshopData(ud);
        // };

        const imageDataChange = (e) => {
            e.preventDefault();
            const ud = Object.assign({}, imageData);
            ud.image = e.target.files[0];
            setimageData(ud);
            // console.log('imageData', imageData);
            // console.log('fajllimrena', imageData)
        }

        const ruajImazhin = (e) => {
            console.log('tuerujtImazhin', imageData.image)
            e.preventDefault();
            const formData = new FormData();
            formData.append('image', imageData.image)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            axios.post("/furnitor/user-store/update-storeimage/" + decode.store, formData, config)
                .then((res) => {
                    console.log(res.data);
                    // console.log('image',formData)
                    getStore();
                    setndryshoImazhin(false);
                    // setnotifyMsg('Imazhi u ndryshuar me sukses!');
                    toast.success('Imazhi u ndryshua me sukses!');
                })
                .catch((err) => {
                    console.log(err)
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                    toast.error('Diçka shkoi gabim!');
                });

            // window.location.reload();
        }
        const renderMsg = (msg) => {
            return (
                <div id='notifyMsg' className='notify-msg'>{msg}
                    <button onClick={handleMsgClose}><i className='fa fa-close'></i></button>
                </div>
            );

        }
        const handleMsgClose = (e) => {
            e.preventDefault();
            setnotifyMsg('');

        }
        const ruajNdryshimet = (e) => {
            e.preventDefault();
            console.log("modifikimet e dyqanit", shopData);

            axios.post("/furnitor/user-store/update/" + decode.store, shopData)
                .then((res) => {
                    console.log(res.data);
                    setisShopEdit(false);
                    toast.success('Ndryshimet u ruajtën me sukses!');
                    // setnotifyMsg('Ndryshimet u ruajtën me sukses!');
                })
                .catch((err) => {
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                    console.log(err);
                    toast.error('Diçka shkoi gabim!');
                });
        };

        const ndryshoDyqanin = (e) => {
            e.preventDefault();
            let userEdit = isShopEdit;

            if (userEdit === true) {
                setisShopEdit(false);
            } else {
                setisShopEdit(true);
            }
        };

        const imazhi = (e) => {
            e.preventDefault();
            let imazhiEdit = ndryshoImazhin;
            if (imazhiEdit) {
                setndryshoImazhin(false);
            } else {
                setndryshoImazhin(true);
            }
        }


        useEffect(() => {
            // const token = window.localStorage.getItem("cd");
            // const decode = token ? jwt_decode(token) : null;
            // if (shopData.length == 0) {

            getStore();
            // }
            axios
                .get("/general/countries/all")
                .then((res) => {
                    // console.log('userData', res.data);
                    setstateData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                });
            axios
                .get("/general/cities/all")
                .then((res) => {
                    setcityData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                });
            axios
                .get("/general/store-categories/all")
                .then((res) => {
                    setcatData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                });
        }, []);


        // const [position, setPosition] = useState(null)
        //
        // console.log(position)
        //
        // const map = useMapEvents({
        //     click() {
        //         map.locate()
        //     },
        //     locationfound(e) {
        //         setPosition(e.latlng)
        //         map.flyTo(e.latlng, map.getZoom())
        //     },
        // })

        // return position === null ? null : (
        //     <Marker position={position}>
        //         <Popup>You are here</Popup>
        //     </Marker>
        // )


// console.log("shopdata", shopData);
// console.log("catBiz", catData);
// console.log("qytetet", cityData);
// console.log("shtetet", stateData);

        console.log('here', shopData)


        function MyComponent() {
            // useEffect(()=>{
            //     map.locate();
            // },[])
            map = useMapEvents({
                // whenReady: () => {
                //     console.log('loaded')
                //     locationCenter();
                // },
                click: async (e) => {
                    let i = await map.mouseEventToLatLng(e.originalEvent)
                    await setMapState([Number(i.lat), Number(i.lng)])
                    console.log(map)
                    await latLongFunc([Number(i.lat), Number(i.lng)]);
                    // await latitude(i.lat);
                    //  latitude(i.lat).then(()=>longitude(i.lng));
                    // setTimeout(()=>latitude(i.lat), 105);
                    console.log('shop data inside click', shopData)
                    // map.locate();
                },
                locationfound: async (location) => {
                    if (shopData?.longitude === 0 && shopData?.latitude === 0) {
                        latLongFunc([location.latlng.lat, location.latlng.lng])
                        await map.panTo([Number(shopData?.latitude), Number(shopData?.longitude, {
                            animate: false,
                        })]);
                        await setCenter([Number(shopData?.latitude), Number(shopData?.longitude)]);
                    }
                    console.log('location found:', [location.latlng.lat, location.latlng.lng]);
                    if (shopData != '') {
                        console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]))
                        console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)])
                        await map.panTo([Number(shopData?.latitude), Number(shopData?.longitude, {
                            animate: false
                        })]);
                        await setCenter([Number(shopData?.latitude), Number(shopData?.longitude)])
                    } else {
                        console.log('po hin te location defauklt')
                        await map.panTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                            animate: false
                        })]);
                        await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
                    }
                    // );
                    // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
                    setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                    setLocated(true);
                    map.stopLocate();
                    // console.log(mapState)
                },
            })
            if (located == false) {
                map.locate();
            }
            return null
        }

        console.log(mapState)
        const locationCenter = () => {
            console.log('loaded2')
            // useMapEvents({}).locate().then((e)=> console.log(`locationcenter ${e}`) )
        }


        console.log('state', shopData)

        let image = siluette;
        return (
            <>
                <div className="biznis-faqja">
                    <div className={'titulli'}></div>

                    <div className="fjeshil-container">
                        <div className="row pl-3 mt-5">
                            <div className="col-md-12">
                                    {/* <img className='store-image'
                                         src={process.env.REACT_APP_BACK_URL+pictureSource}
                                         onError={() => {
                                             setPictureSource(siluette)
                                         }}>
                                    </img> */}
                                    <img className={shopData?.pic_url == null ? 'store-image-icon' : 'store-image'}
                                         src={ shopData?.pic_url == null ? (`${process.env.REACT_APP_BACK_URL}` + catData.find(data => data._id == shopData.store_cat)?.pic_url) : `${process.env.REACT_APP_BACK_URL}${shopData.pic_url}`}
                                        alt='Imazhi i Dyqanit'
                                        // width='312px' style={{margin: '0 auto'}}
                                         >
                                    </img>
                                    <button className="storeimage-btn"
                                            onClick={imazhi}>{ndryshoImazhin ? 'Largohu' : 'Ndrysho Imazhin'}</button>
                                    {ndryshoImazhin &&
                                    <Fragment>
                                        <div className='image-form'>
                                            <form className='shp_groupform' onSubmit={ruajImazhin}>
                                                <div className='row w-100 '>
                                                    <div className='col-6'>
                                                        <input type='file' name='file' id='file'
                                                               onChange={imageDataChange}/>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button type='submit' className=' form-control'
                                                                disabled={!!imageData ? false : true}>Ruaj Imazhin
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Fragment>
                                    }

                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className={"row shp_groupform w-100"}>
                                    <div className={"col-10"}>
                                        <h4 className="pl-2 pb-5">Menaxhimi i Dyqanit</h4>
                                        <form className="form p-2" onSubmit={""}>
                                            <div className="row">
                                                <label className="col-6">Emri i Dyqanit: </label>
                                                <input
                                                    className="col-6 input__button"
                                                    value={shopData?.name}
                                                    onChange={emri}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">Numri Unik Identifikues (NUI): </label>
                                                <input
                                                    className="col-6 input__button"
                                                    value={shopData?.fiscal_nr}
                                                    onChange={nrunik}
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    maxLength="9"
                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 9)}
                                                    disabled={!isShopEdit}
                                                />

                                            </div>
                                            {isShopEdit ?
                                                <p className="fiscal-nr">Numri Unik Identifikues (NUI) mund te shkruhet
                                                    vetem maximum 9 numra.</p> : ''}
                                            <div className="row">
                                                <label className="col-6">Shteti: </label>
                                                <select className="col-6" value={shopData?.city_id} onChange={shteti}
                                                        disabled={!isShopEdit}>
                                                    {/* <option value={null} >Selekto</option> */}
                                                    {stateData?.map((el, index) => {

                                                        return <option value={el._id}>{el.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <label className="col-6">Qyteti: </label>
                                                <select className="col-6"
                                                        value={shopData?.city_id}
                                                        onChange={qyteti}
                                                        disabled={!isShopEdit}>
                                                    {cityData?.map((el, index) => {
                                                        return <option key={index} value={el?._id}>{el?.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {/* <div className="row">
                                                <label className="col-6">Telefoni: </label>
                                                <input
                                                    className="col-6 input__button"
                                                    value={shopData.fiscal_nr}
                                                    onChange={nrunik}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">Address: </label>
                                                <input
                                                    className="col-6 input__button"
                                                    value={shopData.fiscal_nr}
                                                    onChange={nrunik}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">Description: </label>
                                                <textarea
                                                    className="col-6 input__button"
                                                    value={shopData.fiscal_nr}
                                                    onChange={nrunik}
                                                    disabled={!isShopEdit}
                                                />
                                            </div> */}
                                            <div className="row">
                                                <label className="col-6">
                                                    Kategoria e Biznesit:{" "}
                                                </label>
                                                <select className="col-6" value={shopData?.store_cat} onChange={kategoria}
                                                        disabled={!isShopEdit}>
                                                    {catData?.map((el, index) => {
                                                        return <option key={index} value={el._id}>{el.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Gjërsia Gjeografike:{" "}
                                                </label>
                                                <input
                                                    type='text'
                                                    step=".0001"
                                                    className="col-6 input__button"
                                                    value={shopData['latitude'] ?? ' '}
                                                    // onChange={latitude}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Lartësia Gjeografike:{" "}
                                                </label>
                                                <input
                                                    type='text'
                                                    step=".0001"
                                                    className="col-6 input__button"
                                                    value={shopData['longitude'] ?? ' '}
                                                    // onChange={longitude}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Adresa:{" "}
                                                </label>
                                                <input
                                                    type='text'
                                                    className="col-6 input__button"
                                                    value={shopData?.address}
                                                    onChange={adresa}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Telefoni:{" "}
                                                </label>
                                                <input
                                                    type='number'
                                                    name="store_phone"
                                                    className="col-6 input__button"
                                                    value={shopData?.phone}
                                                    onChange={telefoni}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Ora e Fillimit: {" "}
                                                </label>
                                                <input
                                                    type='number'
                                                    className="col-6 input__button"
                                                    value={shopData?.opening_hour}
                                                    onChange={orariHapjes}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Ora e Mbarimit:{" "}
                                                </label>
                                                <input
                                                    type='number'
                                                    className="col-6 input__button"
                                                    value={shopData?.closing_hour}
                                                    onChange={orariMbylljes}
                                                    disabled={!isShopEdit}
                                                />
                                            </div>
                                            <div className="row">
                                                <label className="col-6">
                                                    {" "}
                                                    Përshkrimi:{" "}
                                                </label>
                                                <input
                                                    // name="store_description"
                                                    type="text"
                                                    onChange={description}
                                                    value={shopData?.description}
                                                    disabled={!isShopEdit}
                                                    className="col-6 input__button"
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='col-6 text-center'>
                                                    <button
                                                        className="form-control mb-2"
                                                        onClick={ndryshoDyqanin}
                                                    >

                                                        {isShopEdit ? 'Largohu' : 'Ndrysho Të Dhënat'}
                                                    </button>
                                                </div>
                                                <div className='col-6 text-center'>
                                                    {isShopEdit &&
                                                    <button className="form-control mb-2" onClick={ruajNdryshimet}>Ruaj
                                                        Ndryshimet</button>}
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
                                            <MyComponent/>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            {shopData != '' ?
                                                <Marker position={[shopData?.latitude, shopData?.longitude]}>
                                                    <Popup>
                                                        A pretty CSS3 popup. <br/> Easily customizable.
                                                    </Popup>
                                                </Marker> :
                                                mapState != '' ?
                                                    <Marker position={[mapState[0], mapState[1]]}>
                                                        <Popup>
                                                            A pretty CSS3 popup. <br/> Easily customizable.
                                                        </Popup>
                                                    </Marker> : ''

                                            }
                                        </MapContainer>
                                    </div>
                                </div>

                                {/*<iframe*/}
                                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.028901992423!2d21.156152950823138!3d42.660742923950295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354f2cbab67d493%3A0x5c97e5834932545a!2sNEWBORN!5e0!3m2!1sen!2s!4v1620776523316!5m2!1sen!2s"*/}
                                {/*    width="100%" height="350" title='gmap' style={{border: 'unset'}} allowfullscreen=""*/}
                                {/*    loading="lazy"></iframe>*/}
                                {/* <iframe src={'https://maps.google.com/maps?q='+shopData.latitude+','+shopData.latitude+'&hl=es&z=14&amp;output=embed'} title='maps' width="600" height="450"  allowfullscreen="" loading="lazy" /> */}
                            </div>
                        </div>
                    </div>
                {/* </div> */}

            </>
        );
    }
;
export default BiznesFaqja;
