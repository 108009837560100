import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Loader from '../Loader/loader';
import {CSVLink, CSVDownload} from "react-csv";
import download from 'js-file-download';
// Images
import ListoStokun from './ListoStokun';
import axios from '../../axios';
import jwt_decode from 'jwt-decode';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

//CSS
import './BiznesStoku.scss';
import ListaProdukteve from "../BiznesProduktet/ListaProdukteve";
import ListViewProducts from "../BiznesProduktet/ListViewProducts/ListViewProducts";
import ListViewStock from "./ListView/ListViewStock";

const BiznesStoku = () => {

    let history = useHistory();
    const [stockData, setStockData] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [excelDataUpload, setExcelDataUpload] = useState(null);
    const [productData, setProductData] = useState([])
    const [validationMessage, setValidationMessage] = useState('');
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid')
    const [isXLSXloading, setisXLSXloading] = useState(false);
    const [xlsxMSG, setxlsxMSG] = useState('');
    const [xlsxMSGerr, setxlsxMSGerr] = useState([]);

    const stocksPerList = 50;
    const stocksPerPage = 10;
    const pagesVisited = pageNumber * stocksPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filteredUsers.length / stocksPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;

    useEffect(() => {
        // getCategories();
        const token = window.localStorage.getItem('cd');
        const decode = token ? jwt_decode(token) : null;
        axios.get('/furnitor/store-stock/all/' + decode.store)
            .then((res) => {
                // console.log('axios', res);
                setStockData(res.data);
                setFilteredUsers(res?.data)
                setIsLoading(false);
                // toast.success('Imazhi u ndryshua me sukses!');

            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                history.push("/kyqu");

            })
        axios.get('/furnitor/store-products/all/' + decode.store)
            .then((res) => {
                // console.log('axios', res);
                setProductData(res?.data);
            })
            .catch((error) => {
                console.log(error)
                window.localStorage.removeItem('cd');
                history.push("/kyqu");

            })
    }, []);


    console.log()

    console.log('products incoming ', productData);


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = stockData?.filter((users) => {
            console.log('users incoming', users)
            return (users?.prodData[0]?.name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }

    const openOtherButtonsForExcelUpload = () => {
        setIsOpen(!isOpen);
    }


    //
    let headerss = [
        {label: "First Name", key: "firstname"},
        {label: "Last Name", key: "lastname"},
        // { label: "Email", key: "email" }
    ];


    console.log('is open', isOpen);

    let arrayOfArray = []
    let arrayOfObjects = {}

    //
    // console.log('arrayOf object', dataMap.name, dataMap.id );
    // console.log('array of array', arrayOfArray);
    // console.log('data map', dataMap)

    // let hah = dataMap?.map((el, i) => {
    //
    //         return {label: el?.name, key:  el?.id}
    //
    // })

    // console.log(lol)

    // console.log(dataMap[0].key())
    // console.log(hah[0].id)


    let dataMap = productData?.map((el) => {
        return {id: el._id, name: el?.name};

    })


    // let lol =    Object.getOwnPropertyNames(dataMap[0])


    let headers = [
        {label: "ID", key: "id"},
        {label: "Name", key: "name"}
    ];


    // const downloadXls = async () => {
    //     axios.get( process.env.REACT_APP_BACK_URL + '/uploads/bulk-import-template.xlsx').then().catch(err => console.log(err));

    // }


    const uploadXls = async (e) => {
        e.preventDefault();
        let file = e?.target.filee.files[0];
        let forme = new FormData()
        forme.append('file', file);
        console.log(file)
        setisXLSXloading(true);
        axios.post(`/furnitor/xls/bulk-import/${decode.store}`, forme)
            .then((data) => {
                setisXLSXloading(false);
                if (data.data.success == true) {
                    toast.success('Të dhënat u importuan me sukses!');
                    setxlsxMSG('Të dhënat u importuan me sukses!');
                } else {
                    toast.error('Diçka shkoi gabim!');
                    setxlsxMSG('Diçka shkoi gabim. Reshtat e mëposhtem kane gabime!');
                    setxlsxMSGerr(data.data.data);
                }
                console.log('suksesi', data.data.data);
            })
            .catch((err) => {
                toast.error('Diçka shkoi gabim!');
                console.log(err);
                setxlsxMSGerr(err);
                window.localStorage.removeItem('cd');
                history.push("/kyqu");
            });
    }

    console.log(componentToBeRendered);
    console.log('xls-error', xlsxMSGerr);
    console.log('xls-msg', xlsxMSG);


    return (
        isLoading ? <Loader/> :
            <>
                <div className="biznes_stoku">
                    <div className="fjeshil-container">
                        <div className="row w-100">
                            <div className="col-md-12">
                                <div className="stoku_title">
                                    <h1>Menaxhimi i Stokut</h1>
                                    <div>
                                        <Link to="/shtostokun">
                                            <button>Shto Stokun</button>
                                        </Link>
                                        <button className="buttoni"
                                                onClick={openOtherButtonsForExcelUpload}>{isOpen ? "Largohu" : "Ngarko XLSX"}</button>
                                        {isOpen ?
                                            <div className='uploading'>
                                                {/* <div className='shp_groupform'> */}
                                                {isXLSXloading == true ? <Loader/> :
                                                    <>
                                                        <form onSubmit={(e) => uploadXls(e)}>
                                                            <div className='row'>
                                                                <div className='col-md-9 col-12'>
                                                                    <input type='file'
                                                                           name='filee'
                                                                           id='file'
                                                                           className="input-xlsx"
                                                                        // onChange={uploadXls}
                                                                    />
                                                                </div>
                                                                <div className="col-md-1 col-12">
                                                                    <button type="submit"
                                                                            value="Ngarko XLSX"
                                                                            className='upload-xlsx'
                                                                    >
                                                                        Ngarko XLSX
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {xlsxMSG == 'Të dhënat u importuan me sukses!' ?
                                                            <div className='text-success'> {xlsxMSG}</div> :
                                                            <div className='text-danger'> {xlsxMSG}</div>
                                                        }
                                                        {
                                                            xlsxMSGerr.length > 0 &&
                                                            <div className='row p-3'>
                                                                <table className='table'>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Nr</th>
                                                                        <th>Produkti</th>
                                                                        <th>Kategoria</th>
                                                                        <th>Detajet</th>
                                                                        <th>Me Pagese</th>
                                                                        <th>Kuantiteti</th>
                                                                        <th>Njesija matese</th>
                                                                        <th>Çmimi per kuantitet</th>
                                                                        <th>Data e skadimit</th>
                                                                        <th>Koha e marrjes</th>
                                                                    </tr>
                                                                    </thead>
                                                                    {xlsxMSGerr.map((el) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{el.row}</td>
                                                                                <td>{el.data.produkti}</td>
                                                                                <td>{el.data.kategoria}</td>
                                                                                <td>{el.data.detajet}</td>
                                                                                <td>{el.data.mepages}</td>
                                                                                <td>{el.data.qty}</td>
                                                                                <td>{el.data['njesija matese']}</td>
                                                                                <td>{el.data['cmimi per qty']}</td>
                                                                                <td>{el.data['data skadimit']}</td>
                                                                                <td>{el.data.pickup}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </table>
                                                            </div>
                                                        }
                                                        <div className='download pt-3'><a
                                                            href={process.env.REACT_APP_BACK_URL + '/uploads/bulk-import-template.xlsx'}
                                                            target="_blank">Klikoni këtu që ta shkarkoni shembullin.</a>
                                                        </div>

                                                    </>}
                                                {/* </div> */}
                                            </div> : ''
                                        }
                                    </div>
                                    <div className="col-6 col-sm-6 md-form mt-5 pb-3">
                                        <input onChange={searcher} className="form-control"
                                               type="text" placeholder="Kërko..." aria-label="Search"/>
                                        {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                                    </div>
                                    <div className="button-group">
                                        <button type="button"
                                                onClick={() => setComponentToBeRendered('grid')}
                                                className="btn btn-primary grid-button">
                                            <i className="fa fa-th-large "></i>
                                        </button>
                                        <button type="button"
                                                onClick={() => setComponentToBeRendered('list')}
                                                className="btn btn-primary grid-button">
                                            <i className="fa fa-th-list"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 stoku">
                                {
                                    componentToBeRendered === 'grid' ?
                                        displayUsers = filteredUsers?.slice
                                        (pagesVisited, pagesVisited + stocksPerPage)?.map
                                        (filteredUsers => {
                                            return (
                                                <ListoStokun data={filteredUsers} key={filteredUsers?._id}
                                                />
                                            )
                                        })
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2 w-100'}>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-sm"><b>Emri i produktit</b>
                                                                        </th>
                                                                        <th className="col-sm"><b>Shuma</b></th>
                                                                        {/*<div className="col-sm"><b>Statusi</b></div>*/}
                                                                        <th className="col-sm"><b>Çmimi</b></th>
                                                                        <th className="col-sm"><b>Data e Skadimit</b>
                                                                        </th>
                                                                        <th className="col-sm"><b>Modifiko</b></th>
                                                                        <th className="col-sm"><b>Fshij</b></th>
                                                                        {/*<div className="col-1"><b>Statistikat</b></div>*/}
                                                                    </tr>
                                                                    </thead>
                                                                    {
                                                                        componentToBeRendered === 'list' ?
                                                                            displayUsers = filteredUsers?.slice
                                                                            (pagesVisited, pagesVisited + stocksPerList)?.map
                                                                            (filteredUsers => {
                                                                                return (
                                                                                    <ListViewStock data={filteredUsers}
                                                                                                   key={filteredUsers?._id}
                                                                                    />
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        componentToBeRendered === 'list' ?
                            <div className="row ">
                                <div className="col-12">
                                    {stockData.length >= stocksPerList && <ReactPaginate
                                        previousLabel={"Mbrapa"}
                                        nextLabel={"Para"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                    }
                                </div>
                            </div>
                            :
                            ''
                    }
                    {
                        componentToBeRendered === 'grid' ?
                            <div className="row ">
                                <div className="col-12">
                                    {stockData.length >= stocksPerPage && <ReactPaginate
                                        previousLabel={"Mbrapa"}
                                        nextLabel={"Para"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                    }
                                </div>
                            </div>
                            :
                            ''
                    }
                </div>
            </>
    );
};

export default BiznesStoku;
