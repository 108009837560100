import axios from '../../axios';
import {Link} from "react-router-dom";
import './ForgotPassword.scss';
import {useState} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

const ForgotPassword = () => {

    const [errorMsg, setErrorMsg] = useState('')

    const submitForgotPassword = async (event) => {
        event.preventDefault();

        let email = event.target.email.value;
        try {
            let data = await axios.post('general/forgot-password/get-link', {
                email: email
            })
            setErrorMsg(data?.data?.msg)
            toast.success('Kontrolloni emailin tuaj për të vazhduar me resetimin e fjalëkalimit!');
        } catch (error) {
            console.log(error.response.data.msg)
            toast.error('Diçka shkoi gabim!');
        }
    }


    return (
        <>
            <div className="row mt-5 ForgotPassword">
                <div className="col-md-12">
                    <h2 className="title">Keni harruar fjalëkalimin!?</h2>
                    <form onSubmit={submitForgotPassword}>
                        <div className="login_formgroup">
                            <div className="input-container">
                                <i className="fa fa-user icon"></i>
                                <input
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="emaili juaj"
                                    required
                                />
                            </div>
                            <div className="btn_signin">
                                <button type="submit">Dërgo</button>
                            </div>
                            <p className="forgot-password">{errorMsg}</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;