import React from 'react';
import {FaExpand} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import manuali_punetorit from '../../utils/Manuali Furnitorit - finale.pdf'
//CSS
import './BiznesSidebar.scss';


const BiznesSidebar = () => {


    const signout = () => {
        window.localStorage.removeItem('cd');
        window.location = "/kyqu";
    }

    const pdfFile = () => {

    }


    return (
        <>
            <div className="biznes_sidebar">
                <div className="biznes_menu">

                    <ul>
                        <li>
                            <Link to="/">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Paneli i Kontrollit
                            </Link>
                        </li>
                        <li>
                            <Link to="/dyqani">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Menaxhimi i Dyqanit
                            </Link>
                        </li>
                        <li>
                            <Link to="/shfrytezuesi">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Menaxhimi i Shfrytëzuesit
                            </Link>
                        </li>
                        <li>
                            <Link to="/menaxhoproduktet">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Menaxhimi i Produkteve
                            </Link>
                        </li>
                        <li>
                            <Link to="/biznesstoku">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Menaxhimi i Stokut
                            </Link>
                        </li>
                        <li>
                            <Link to="/transactions">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Menaxhimi i Transaksioneve
                            </Link>
                        </li>
                        <li>
                            <Link to="/statistikat">
                <span>
                  <FaExpand/>{' '}
                </span>
                                Statistikat
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="signout">
                    <Link onClick={signout}>
                        <span>Shkyçu</span>
                    </Link>
                    <a href={manuali_punetorit} target="_blank" title="Manuali i Furnitorit"><i
                        class="fa fa-question-circle"></i></a>
                </div>
            </div>
        </>
    );
};

export default BiznesSidebar;
