import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import axios from "../../../axios";
// Images
import Statistikat from '../../../images/Statistics.png';

// CSS
import './TeRealizuara.scss';
import moment from "moment";
import {Bar} from "react-chartjs-2";
import ReactPaginate from "react-paginate";
import Loader from "../../Loader/loader";
import ListView from "../ListView/ListView";


const TeRealizuara = (props) => {
    // const [active, setActive] = useState(true);

    // const [active, setActive] = useState(true);
    const [transactionsList, setTransactionsList] = useState([]);

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [soldAmount, setSoldAmount] = useState([]);
    const [componentToBeRendered, setComponentToBeRendered] = useState('grid');


    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;


    // const getTransactionsList = async () => {
    //     try {
    //         let data = await axios.get(`/furnitor/store-transactions/new/${decode.store}`)
    //         console.log(data)
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }
    //
    // useEffect(() => {
    //     getTransactionsList();
    // },[])


    const getProductsCategoryStatistics = async () => {
        try {
            let statsData = await axios.get(`/furnitor/store-transactions/statistic/sold-amount/${decode.store}`)
            console.log('stats data incoming', statsData.data)
            setSoldAmount(statsData.data)
        } catch (error) {
            console.log(error)
        }
    }

    // const getProductsCategoryStatistics = async () => {
    //     try {
    //         let statsData = await axios.post('/furnitor/store-products/statistic/product-category/', deco{
    //             category_id :d
    //                 })
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }
    //
    // const getProductsCategoryStatistics = async () => {
    //     try {
    //         let statsData = await axios.post('/furnitor/store-products/statistic/product-category/', deco{
    //             category_id :
    //                 })
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
    let displayUsers = [];


    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    const chartData = {
        labels: [...soldAmount.map((el) => el?.name)],
        datasets: [
            {
                label: 'Shuma për Produkt',
                data: [...soldAmount.map((el) => el?.total_price)],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: false,
                    },
                },
            ],
        },
    };


    const getDone = () => {
        // console.log("DONEEEEE")
        // console.log('EXPIRED');
        axios.get('/furnitor/store-transactions/done/' + decode.store)
            .then((data) => {
                // setUneditedTransactionList(data.data);
                setTransactionsList(data?.data);
                console.log(data.data)
                setFilteredUsers(data?.data);
                setIsLoading(false);
                // setIsLoading(false);
                // changePage({selected: 0});

                // setPageNum(0)
            })
            .catch(err => {
                console.log(err)
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            })
    }


    const searcher = async (text) => {
        let searchText = text?.target.value.toLowerCase();
        let filteredUser = transactionsList?.filter((users) => {
            console.log('users incoming', users)
            return (users?.user_name.toLowerCase().includes(searchText))
        })
        setFilteredUsers(filteredUser)
        setPageNumber(0);
    }


    useEffect(() => {
        // getNewUsers();
        // getUserStats();
        getDone();
        getProductsCategoryStatistics();
    }, [])


    return (isLoading ? <Loader/> :
            <>
                <div className="row TeRealizuara">
                    <div className="col-6 col-sm-6 md-form mt-5 pb-3">
                        <input className="form-control"
                               type="text" onChange={searcher} placeholder="Kërko..." aria-label="Search"/>
                        {/*<i className="fa fa-search search__icon" aria-hidden="true"></i>*/}
                    </div>
                    <div className="col-md-12">
                        <div className="statistikat1">
                            <p>Statistikat e Transaksioneve për Produkte Të Realizuara</p>
                            <div className="statistikat">
                                <Bar data={chartData} options={options}/>
                            </div>
                        </div>
                        <div className="button-group">
                            <button type="button" onClick={() => setComponentToBeRendered('grid')}
                                    className="btn btn-primary grid-button">
                                <i className="fa fa-th-large "></i>
                            </button>
                            <button type="button" onClick={() => setComponentToBeRendered('list')}
                                    className="btn btn-primary grid-button">
                                <i className="fa fa-th-list"></i>
                            </button>
                        </div>

                        <div className="col-md-9">
                            <div className="op">
                                {
                                    componentToBeRendered === 'grid' ?
                                        <>
                                            {filteredUsers?.length > 0 ? displayUsers = filteredUsers?.slice
                                                (pagesVisited, pagesVisited + usersPerPage)?.map
                                                (filteredUsers => {
                                                    // console.log('jejejejejejejjejejejjej', filteredUsers)
                                                    let date = moment(filteredUsers?.order_date).format('D/MM/YYYY');
                                                    return (
                                                        <>
                                                            <div key={filteredUsers?.name} className="kerkesa_tereja">
                                                                <div className="emri_produktit">
                                                                    <p className="produkt">{filteredUsers?.product_name}</p>
                                                                    {/*<p className="person">{el?.}</p>*/}
                                                                    <p className="person">{filteredUsers?.user_name}</p>

                                                                </div>
                                                                <div className="data">
                                                                    <p className="data_text">Sasia</p>
                                                                    <p className="person">{filteredUsers?.qty + " " + filteredUsers?.messurment_name}</p>
                                                                </div>
                                                                <div className="data">
                                                                    <p className="data_text">Çmimi Total</p>
                                                                    <p className="person">€{filteredUsers?.total_price}</p>
                                                                </div>
                                                                <div className="data">
                                                                    <p className="data_text">Data</p>
                                                                    <p className="data_">{date}</p>
                                                                </div>
                                                                <hr/>
                                                            </div>

                                                            
                                                        </>
                                                    )
                                                })
                                                : <span className="display-msg">Nuk ka asnjë kërkesë të realizuar</span>
                                            }
                                        </>
                                        : ''
                                }
                                {
                                    componentToBeRendered === 'list' ?
                                        <>
                                            <div className="ListProducts">
                                                <div className='row w-100 ml-1'>
                                                    <div className={'listimi-transactions sticky-top'}>
                                                        <div className={'row p-2 w-100'}>
                                                            <div className=" col-sm"><b>Emri i produktit</b></div>
                                                            <div className=" col-sm"><b>Shfrytëzuesi</b></div>
                                                            <div className=" col-sm"><b>Sasia</b></div>
                                                            <div className=" col-sm"><b>Çmimi</b></div>
                                                            <div className=" col-sm"><b>Data</b></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {filteredUsers?.length > 0 ? displayUsers = filteredUsers?.slice
                                                (pagesVisited, pagesVisited + usersPerPage)?.map
                                                (filteredUsers => {
                                                    console.log('jejejejejejejjejejejjej', filteredUsers)
                                                    let date = moment(filteredUsers?.order_date).format('D/MM/YYYY');
                                                    return (
                                                        <>
                                                            <ListView filtered={filteredUsers} date={date}/>
                                                        </>
                                                    )
                                                })
                                                : <span className="display-msg">Nuk ka asnjë kërkesë të realizuar</span>
                                            }
                                        </>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 ml-5">
                        <div className="col-md-12">
                            {
                                filteredUsers?.length > 8 ?
                                    <ReactPaginate
                                        previousLabel={"Mbrapa"}
                                        nextLabel={"Para"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </>
    );
};

export default TeRealizuara;
