import './MobileSidebar.scss';
import {Link, NavLink} from "react-router-dom";
import { useHistory } from "react-router-dom";
import logoFoodSafe from '../../images/newfoodsafe-resized.png'

function MobileSidebar() {



    let history = useHistory()

    function openCloseNavBar() {
        console.log('POOOO HYYYN')
        console.log('nuk po hyyyyyyyyyyyyyyyyyyyyn')
        let x = document.getElementById("myLinks");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    }

    // const closeNavBar = () => {
    //     let x = document.getElementsByClassName('mobile-container');
    //     x.style.display = 'none';
    // }
    const signout = () => {
        window.localStorage.removeItem('cd');
        history.push('/kyqu')
    }

    return (
        <>
            <div className="topnav">
                {/*<img src={logoFoodSafe} className="test-img" alt="heh" />*/}
                <a href="#home" class="active"><img src={logoFoodSafe} className="test-img" alt="heh" /></a>
                <div id="myLinks">
                    <NavLink to="/">
                        {/*<i className="fa fa-tachometer fa-1x"></i>*/}
                        <p className="dashboard--text">Paneli i Kontrollit</p>
                    </NavLink>
                    <NavLink to="/dyqani">
                        <p className="dashboard--text">Menaxhimi i Dyqanit</p>
                    </NavLink>
                    <NavLink to="/shfrytezuesi">
                        {/*<i className="fa fa-tachometer fa-2x"></i>*/}
                        <p className="dashboard--text">Menaxhimi i Shfrytëzuesit</p>
                    </NavLink>
                    <NavLink to="/menaxhoproduktet">
                        {/*<i className="fa fa-tachometer fa-2x"></i>*/}
                        <p className="dashboard--text">Menaxhimi i Produkteve</p>
                    </NavLink>
                    <NavLink to="/biznesstoku">
                        {/*<i className="fa fa-tachometer fa-2x"></i>*/}
                        <p className="dashboard--text">Menaxho Stokun</p>
                    </NavLink>
                    <NavLink to="/transactions">
                        {/*<i className="fa fa-tachometer fa-2x"></i>*/}
                        <p className="dashboard--text">Transaksionet</p>
                    </NavLink>

                        {/*<i className="fa fa-tachometer fa-2x"></i>*/}
                    <div className="link-group">
                        <Link onClick={signout}>
                            <p className="dashboard--text">Shkyçu</p>
                        </Link>
                        {/*<Link onClick={signout}>*/}
                        {/*    <p className="dashboard--text">Help</p>*/}
                        {/*</Link>*/}
                    </div>

                </div>
                <a href="javascript:void(0);"  onClick={openCloseNavBar} class="icon">
                    <i className="fa fa-bars"></i>
                </a>
            </div>
        </>
    )
}


export default MobileSidebar;