import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// Images
import DeleteIcon from '../../images/delete.svg';
import EditIcon from '../../images/edit.svg';
import Category from '../../images/category.png';

// CSS
import './Transactions.scss';
import jwt_decode from "jwt-decode";

const ListimiTransakcionet = (props) => {

    const [errMsg, setErrMsg] = useState('')

    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;

    console.log(props?.data)


    // console.log(props.data.store_prod[0].name)


    // console.log('data incoming', props.data.url)


    console.log('error mesazhi error mesazhi', errMsg)


    console.log('typop typo typo typo ', props.data)

    let orderDate = props?.data.order_date.slice(0,10)

    // console.log('order date',orderDate)

    return (
        <>
            <tbody>
            <tr>
                {/*<th scope="row">1</th>*/}
                <td>{props?.data.user_email}</td>
                <td> {props?.data.product_name}</td>
                <td> {props?.data.qty}</td>
                <td>{props?.data.total_price + ' EUR'}</td>
                <td>{props?.data.type === 'Free' ? 'Falas' : props?.data.type === 'Paid' ? 'Paguar' : ''}</td>
                <td>{props?.data.store_name}</td>
                <td>{props?.data.status === 'expired' ? 'Skaduar' : props.data.status === 'declined' ? 'Refuzuar' : props.data.status === 'approved' ? 'Miratuar' : ''}</td>
                <td>{orderDate}</td>
            </tr>
            </tbody>
            {/*<div className={'listimi-transactions '}>*/}
            {/*    <div className={'row p-2 w-100'}>*/}
            {/*        <div className="col-2 text-break"> {props?.data.user_email}</div>*/}
            {/*        <div className="col-2 text-break"> {props?.data.product_name}</div>*/}
            {/*        <div className="col-1"> {props?.data.qty}</div>*/}
            {/*        <div className="col-2">{props?.data.total_price + ' EUR'}</div>*/}
            {/*        <div className="col-2"> {props?.data.type}</div>*/}
            {/*        <div className="col-2"> {props?.data.store_name}</div>*/}
            {/*        <div className="col-1">{props?.data.status}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default ListimiTransakcionet;
