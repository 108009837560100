import './ListViewProducts.scss';
import {Link} from "react-router-dom";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";
import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";

const ListViewProducts = (props) => {

    // console.log('', props?.data?.active)

    return (
        <>
            <tbody>
            <tr>
                <td className="col-sm">{props.data.name} </td>
                <td className="col-sm-3"> {props?.data?.store_prod[0].name}</td>
                <td className="col-sm-3"> {props?.data?.active ? "Aktiv" : "Pasiv"}</td>
                <td className="col-sm-3">
                    {
                        <img
                            src={process.env.REACT_APP_BACK_URL + (props.data.pic_url != null ? props.data.pic_url : props.data?.store_prod[0]?.pic_url)}
                            alt='produkti'
                            className="picture-location"
                        />
                    }
                </td>
                <td className="col-sm">
                    {
                        <Link to={"/editoprodukt/" + props.data._id}>
                            <img
                                alt="delete"
                                src={EditIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-sm">
                    {
                        <Link to={"/fshijprodukt/" + props.data._id}>
                            <img
                                alt="delete"
                                src={DeleteIcon}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
                <td className="col-sm">
                    {
                        <Link to={"/singleproduct/" + props.data._id}>
                            <img
                                alt="delete"
                                src={Reports}
                                className='actions'
                            />
                        </Link>
                    }
                </td>
            </tr>
            </tbody>
        </>
    )
}

export default ListViewProducts;