import './Statistikat.scss';
import React, {useEffect, useState} from "react";
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import {getAllByDisplayValue} from "@testing-library/react";
import {Bar, HorizontalBar} from "react-chartjs-2";
import Loader from "../Loader/loader";
import Gradient from "javascript-color-gradient";

const Statistikat = () => {

    let date = new Date();
    const [customDateInputValidation, setCustomDateInputValidation] = useState('');
    const [statsPerCategory, setByCategory] = useState([]);
    const [statsPerProduct, setStatsPerProduct] = useState([]);
    const [statsPerTopTen, setStatsPerTopTen] = useState([]);
    const [allStats, setAllStats] = useState([]);
    const [completedStats, setCompletedStats] = useState([]);
    const [categoryStatsTrans, setCategoryStatsTrans] = useState([])
    const [donation, setDonation] = useState([])
    const [donationPerProduct, setDonationPerProduct] = useState([])
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const token = window.localStorage.getItem('cd');
    const [isLoading, setIsLoading] = useState(null);
    const decode = token ? jwt_decode(token) : null;

    // console.log('decode', decode)

//  Ngjyrat


    const colorGradient = new Gradient();

    const color1 = "#3F2CAF";
    const color2 = "#e9446a";
    const color3 = "#edc988";
    const color4 = "#607D8B";

    colorGradient.setMidpoint(10);

    colorGradient.setGradient(color1, color2, color3, color4);

    console.log('ngjyrat', colorGradient.getArray());
    console.log('ngjyra', colorGradient.getColor(1));

//

    const callAllFunctions = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true)
            await getAllTransactions(event);
            await getCompletedTransactions(event);
            await getStatsPerCategory(event);
            await getTopTenPerStats(event);
            await getStatsPerProduct(event);
            await getMeasurementTransactions(event);
            await getDonationPerProduct(event)
            await getDonationTotal(event)
            setTimeout(() => {
                setIsLoading(false)
            }, 1500)
        } catch (error) {
            console.log(error)
        }

    }


    const getAllTransactions = async (event) => {
        console.log('HEREEEEEEEEEEEEEEEEEEEEEEE get ALL TRANS')
        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide");
            return;
        } else {
            setCustomDateInputValidation('')
        }
        //
        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/furnitor/newstatistics/alltransactions/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)
            console.log(response)
            setAllStats(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getMeasurementTransactions = async (event) => {
        console.log('HEREEEEEEEEEEEEEEEEEEEEEEE get ALL TRANS')
        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide");
            return;
        } else {
            setCustomDateInputValidation('')
        }
        //
        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/furnitor/stats/permeasurement/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`)
            console.log(response)
            setCategoryStatsTrans(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getCompletedTransactions = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }


        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }


        try {
            let response = await axios.get(`/furnitor/newstatistics/completedtransactions/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setCompletedStats(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getStatsPerCategory = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }


        try {
            let response = await axios.get(`/furnitor/newstatistics/percat/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setByCategory(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getTopTenPerStats = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }


        try {
            let response = await axios.get(`/furnitor/newstatistics/top10/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setStatsPerTopTen(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getStatsPerProduct = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/furnitor/newstatistics/perprod/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setStatsPerProduct(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getDonationTotal = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/furnitor/stats/donationtotal/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setDonation(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    console.log('DONAAAAAAAAAATION', donation)


    const getDonationPerProduct = async (event) => {


        let objectDate = {
            firstDayOfMonth: event.target.firstDateOfMonth.value,
            customDayOfMonth: event.target.customDayOfMonth.value
        }

        if (objectDate.customDayOfMonth === '' || objectDate.firstDayOfMonth === '') {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        if (objectDate.customDayOfMonth.length > 10 || objectDate?.customDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length < 10 || objectDate.firstDayOfMonth.length > 10) {
            setCustomDateInputValidation("Ju lutemi shtypeni nje date valide!");
            return;
        } else {
            setCustomDateInputValidation('')
        }

        try {
            let response = await axios.get(`/furnitor/stats/donationperprodukt/${decode.store}/${objectDate.firstDayOfMonth}/${objectDate.customDayOfMonth}`);
            console.log(response)
            setDonationPerProduct(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    //
    // console.log('completed', completedStats)
    // console.log('top 10', statsPerTopTen);
    // console.log('prodct', statsPerProduct);
    // console.log('category', statsPerCategory);
    // console.log('all', allStats);


    console.log('custom validation input', customDateInputValidation)

    useEffect(() => {

    }, []);


    const chartData = {
        labels: [...allStats?.map((el => {
            if (el?.status === 'approved') {
                return 'Aprovuar'
            }
            if (el?.status === 'initial') {
                return 'Inicuar'
            }
            if (el?.status === 'expired') {
                return 'Skaduar'
            }
            if (el?.status === 'declined') {
                return 'Refuzuar'
            } else {
                return el.status;
            }
        }))], // statusin
        datasets: [
            {
                label: 'Totali i Transaksioneve', // statusin
                data: [...allStats?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const chartDataQty = {
        labels: [...allStats?.map((el => {
            if (el?.status === 'approved') {
                return 'Aprovuar'
            }
            if (el?.status === 'initial') {
                return 'Inicuar'
            }
            if (el?.status === 'expired') {
                return 'Skaduar'
            }
            if (el?.status === 'declined') {
                return 'Refuzuar'
            } else {
                return el.status;
            }
        }))], // statusin
        datasets: [
            {
                label: 'Sasia e Transaksioneve', // statusin
                data: [...allStats.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    const topTen = {
        labels: [...statsPerTopTen?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Top 10 Totali', // statusin
                data: [...statsPerTopTen?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const topTenQty = {
        labels: [...statsPerTopTen?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Top 10 Sasia', // statusin
                data: [...statsPerTopTen?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const categoryStats = {
        labels: [...statsPerCategory?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali për Kategori', // statusin
                data: [...statsPerCategory?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const categoryStatsQty = {
        labels: [...statsPerCategory?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Sasia për Kategori', // statusin
                data: [...statsPerCategory?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const productStats = {
        labels: [...statsPerProduct?.map((el => el?.name))], // statusin

        // datasets: [
        //     ...statsPerProduct?.map(((el, index) => {
        //      return {
        //         label: [el?.name],
        //         data: [el?.total_price],
        //         backgroundColor: colorGradient.getColor(index+1)
        //         // {
        //         //     return '#' + hex;
        //         //   })
        //         }
        //     }))
        // ]
        datasets: [
            {
                label: 'Të Gjitha Statistikat', // statusin
                data: [...statsPerProduct?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const productStatsQty = {
        labels: [...statsPerProduct?.map((el => el?.name))], // statusin
        // datasets: [
        //     ...statsPerProduct?.map(((el, index) => {
        //      return {
        //         label: [el?.name],
        //         data: [el?.total_qty],
        //         backgroundColor: colorGradient.getColor(index+1)
        //         // {
        //         //     return '#' + hex;
        //         //   })
        //         }
        //     }))
        // ]
        datasets: [
            {
                label: 'Sasia për Produkt', // statusin
                data: [...statsPerProduct?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const measurementCategory = {
        labels: [...categoryStatsTrans?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Totali për Kategori te matjes', // statusin
                data: [...categoryStatsTrans?.map((el => el?.total_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const measurementCategoryQty = {
        labels: [...categoryStatsTrans?.map((el => el?.name))], // statusin
        datasets: [
            {
                label: 'Sasia për Kategori te matjes', // statusin
                data: [...categoryStatsTrans?.map((el => el?.total_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const donationPerProductStats = {
        labels: [...donationPerProduct?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Totali i donacionit per produkt', // statusin
                data: [...donationPerProduct?.map((el => el?.donation_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const donationProductQty = {
        labels: [...donationPerProduct?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Sasia e donacionit per produkt', // statusin
                data: [...donationPerProduct?.map((el => el?.donation_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const allDonation = {
        labels: [...donation?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Totali i donacionit', // statusin
                data: [donation?.map((el => el?.donation_price))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const allDonationQty = {
        labels: [...donation?.map((el => el?._id))], // statusin
        datasets: [
            {
                label: 'Sasia e donacionit', // statusin
                data: [donation?.map((el => el?.donation_qty))],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }


    console.log('measurement', categoryStatsTrans)


    let formatedDate = moment(firstDayOfMonth).format();
    let firstDayOfTheMonth = formatedDate.toString().slice(0, 10);
    let today = moment(new Date()).format().toString().slice(0, 10);


    return (
        <>
            <div className="biznes_produktet">
                <div className="fjeshil-container mt-5">
                    <div className="Statistikat ">
                        <div className="row mt-5"></div>
                        <h1>Statistikat</h1>
                        <form onSubmit={callAllFunctions}>
                            <div className="row mt-5 form-input">
                                <div className="input-container">
                                    <div className={'col-md-2 col-12 col-sm-12'}>

                                        <input type='date'
                                               className="form-control first-date"
                                               name="firstDateOfMonth"
                                               defaultValue={firstDayOfTheMonth}
                                        />
                                    </div>
                                    <div className={'col-md-2 col-12 col-sm-12'}>
                                        <input type='date'
                                               className="form-control first-date"
                                               name="customDayOfMonth"
                                               defaultValue={today}
                                        />
                                    </div>
                                    <div className="col-md-2 col-sm-12">
                                        <button type="submit"
                                                value="Search by Date"
                                                className='btn btn-success search-button'>
                                            Kërko
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p className="validation-text">{customDateInputValidation}</p>
                        </form>
                        {
                            isLoading ? <Loader/> :
                                <>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali i Transaksioneve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={chartData} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia e Transaksioneve</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={chartDataQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Top 10 Totali</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={topTen} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Top 10 Sasia </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={topTenQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 myheight">
                                        <div className="col-md-6">
                                            <h3>Totali për Produkt</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={productStats} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Produkt </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={productStatsQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Kategori</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={categoryStats} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Kategori</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={categoryStatsQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Kategori te matjes</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={measurementCategory} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Kategori te matjes</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={measurementCategoryQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Donacione te Produktit</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={donationPerProductStats} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Donacione te Produktit</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={donationProductQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <h3>Totali për Donacione</h3>
                                            <div className="col-md-12 ">
                                                <Bar data={allDonation} options={options}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h3>Sasia për Donacione </h3>
                                            <div className="col-md-12 ">
                                                <Bar data={allDonationQty} options={options}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Statistikat;