import React, {useState} from 'react';
import {FaExpand} from 'react-icons/fa';
import {Link} from 'react-router-dom';
// import {SidebarData} from "../BiznesSidebar/"
// import SubNav from "./SubNav"
import jwt_decode from 'jwt-decode';
// CSS
// import './Sidebar.scss';
import logo from '../../images/logo_white.png';

const token = window.localStorage.getItem('cd');
const role = token ? jwt_decode(token).role_id : null;

const MobileMenu = () => {

    const [open, setopen] = useState(false);


    const signout = (e) => {
        e.preventDefault();
        window.localStorage.removeItem('cd');
        window.location = "/kycu";
    }
    return (
        <>
            <div className="mobile_menu">
                <div className='row text-light'>
                    <div className='col-9'><img src={logo} alt={logo} className='mobile-logo'/></div>
                    <div className='col-2'>
                        <button className='menu-button' onClick={() => setopen(!open)}>
                            <i className={open ? 'fa fa-times' : 'fa fa-bars'}></i>
                        </button>
                    </div>
                </div>
                {open &&
                <div className='mobile-data'>
                    <ul>
                        <li onClick={() => setopen(!open)} className="li-li">
                            <li>
                                <Link to="/">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Paneli i Kontrollit
                                </Link>
                            </li>
                            <li>
                                <Link to="/dyqani">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Menaxhimi i Dyqanit
                                </Link>
                            </li>
                            <li>
                                <Link to="/shfrytezuesi">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Menaxhimi i Shfrytëzuesit
                                </Link>
                            </li>
                            <li>
                                <Link to="/menaxhoproduktet">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Menaxhimi i Produkteve
                                </Link>
                            </li>
                            <li>
                                <Link to="/biznesstoku">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Menaxhimi i Stokut
                                </Link>
                            </li>
                            <li>
                                <Link to="/transactions">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Menaxhimi i Transaksioneve
                                </Link>
                            </li>
                            <li>
                                <Link to="/statistikat">
                <span>
                  <FaExpand/>{' '}
                </span>
                                    Statistikat
                                </Link>
                            </li>
                        </li>
                        <Link>
                            <li className={'shkyqu'}>
                                <FaExpand value={{color: "#00000", className: "global-class-name"}}/>
                                <a onClick={signout}>
                            <span>
                            Shkyçu
                             </span>
                                </a>
                            </li>
                        </Link>
                    </ul>
                </div>
                }
            </div>
        </>
    );
};

export default MobileMenu;
