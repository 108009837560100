import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

// Images
import Statistikat from '../../images/Statistics.png';

// CSS
import './BiznesShitjet.scss';
import TeRealizuara from "./TeRealizuara/TeRealizuara";
import TeSkaduara from "./TeSkaduara/TeSkaduara";
import TeReja from "./TeReja/TeReja";
import jwt_decode from "jwt-decode";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/loader";

const BiznesShitjet = () => {
    // const [active, setActive] = useState(true);
    const [componentToBeRendered, setComponentToBeRendered] = useState('new');

    // new/ old/ done
    const [transactionsList, setTransactionsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [soldAmount, setSoldAmount ] = useState([]);
    const [totalOrders, setTotalOrders] = useState([]);
    const [totalAmount, setTotalAmount] = useState(null);
    const [totalPending, setTotalPending] = useState(null);
    const [totalCount, setTotalCount] = useState(null);
    const [userData, setUserData] = useState(null)
    const [dailyTotal, setDailyTotal] = useState(null)

    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;
    const [uneditedTransactionList, setUneditedTransactionList] = useState([]);
    const [pageNum, setPageNum] = useState(1);


    console.log('dececec', decode)
    console.log(uneditedTransactionList);



    let color1 = ''
    let color2 = ''
    let color3 = ''

    if (componentToBeRendered === 'old') {
        color1 = '#000';
    } else {
        color1 = '#bbc5d5';
    }
    //
    if (componentToBeRendered === 'new') {
        color3 = '#000';
    } else {
        color3 = '#bbc5d5';
    }

    if (componentToBeRendered === 'done') {
        color2 = '#000';
    } else {
        color2 = '#bbc5d5';
    }



    const getDailyTotal = async () => {
        try {
            let response = await axios.get(`/furnitor/stats/total-amount-daily/${decode.store}`)
            console.log('RESPONSEE', response)
            setDailyTotal(response.data[0]?.total_price)
        } catch (error) {
            console.log(error)
        }
    }


    const getTotalAmountOfProducts = async () => {
        try {
            let data = await axios.get(`/furnitor/statistics/published-amount/${decode.store}`)
            // console.log('plastuara',data.data.length)
            setTotalOrders(data?.data.length);
        } catch(error) {
            console.log(error)
        }
    }

    const getTotalSum = async () => {
        try {
            let data = await axios.get(`/furnitor/statistics/total-amount/${decode.store}`);
            setTotalAmount(data?.data[0].total_price);
        } catch (error) {
            console.log(error)
        }
    }

    const getTotalPending = async () => {
        try {
            let data = await axios.get(`/furnitor/statistics/total-pending/${decode.store}`);
            setTotalPending(data?.data[0].total_count);
        } catch (error) {
            console.log(error)
        }
    }

    const getTotalCount = async () => {
        try {
            let data = await axios.get(`/furnitor/statistics/total-count/${decode.store}`);
            setTotalCount(data?.data[0].total_count);
        } catch (error) {
            console.log(error)
        }
    }



    const [pageNumber, setPageNumber] = useState(0);


    const getNew = () => {
        // console.log('NEW');
        axios.get('/furnitor/store-transactions/new/' + decode.store)
            .then((data) => {
                // setTransactionsList(data.data);
                setUneditedTransactionList(data?.data);
                setTransactionsList(data.data.slice(0,8));
                console.log('dicka test',data)
                setIsLoading(false);
                // changePage({selected: 0});
                setPageNum(0)
            })
            .catch(err => {
                console.log(err)
                window.localStorage.removeItem('cd');
                window.location = "/kyqu";
            })
    }


    const getExpired = () => {
        // console.log('EXPIRED');
        axios.get('/furnitor/store-transactions/old/' + decode.store)
            .then((data) => {
                // setTransactionsList(data.data);
                setUneditedTransactionList(data?.data);
                setTransactionsList(data.data.slice(0,8));
                console.log(data.data)
                setIsLoading(false);
                // changePage({selected: 0});

                setPageNum(0)
            })
            .catch(err => {
                console.log(err)
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }

    const getDone = () => {
        // console.log("DONEEEEE")
        // console.log('EXPIRED');
        axios.get('/furnitor/store-transactions/done/' + decode.store)
            .then((data) => {
                setUneditedTransactionList(data?.data);
                setTransactionsList(data.data.slice(0,8));
                console.log(data.data)
                setIsLoading(false);
                // changePage({selected: 0});

                // setPageNum(0)
            })
            .catch(err => {
                console.log(err)
                // window.localStorage.removeItem('cd');
                // window.location = "/kyqu";
            })
    }




    useEffect( () => {
        if (componentToBeRendered == 'new') {
            // console.log('new new new')
            getNew();
            setPageNum(0)
            setPageNumber(0);
        }
        if (componentToBeRendered == 'done') {
            // console.log('done done done');
            getDone();
            setPageNum(0)
            setPageNumber(0);
        }
        if (componentToBeRendered == 'old') {
            // console.log('old old old');
            getExpired();
            setPageNum(0)
            setPageNumber(0);
        }
         getTotalAmountOfProducts();
         getTotalSum();
         getTotalPending();
         getTotalCount();
         getDailyTotal()
         // getFurnitor();
        // getTransactionsList();
    }, [componentToBeRendered])




    console.log('transaction list incoming',uneditedTransactionList);




    return (isLoading ? <Loader/> :
        <>
            <div className="biznes_shitjet">
                <div className="row">
                    <div className="col-md-9 dashboard">
                        <div className="container-fluid">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="dashboard_title">
                                        <h1>Paneli i Kontrollit <span>- Furnitori: {localStorage.getItem('name')}  {localStorage.getItem('surname')}</span></h1>
                                        {/* <p style={{textAlign: 'right', fontSize: '16px', fontWeight: '700'}}></p> */}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="charts">
                                        <div className="teardhurat_total">
                                            <p>Shuma Totale</p>
                                            <hr/>
                                            <span className="price">EUR {totalAmount}</span>
                                        </div>
                                        <div className="teardhurat_total">
                                            <p>Të aprovuara</p>
                                            <hr/>
                                            <p className="price">{totalCount}</p>
                                        </div>
                                        <div className="teardhurat_total">
                                            <p>Në pritje të aprovimit</p>
                                            <hr/>
                                            <p className="price">{totalPending}</p>
                                        </div>
                                        <div className="teardhurat_total">
                                            <p>Shuma Ditore</p>
                                            <hr/>
                                            <p className="price">EUR {dailyTotal}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 kerkesat">
                                    <div className="submenu">
                                        <p onClick={() => setComponentToBeRendered('new')} style={{color: `${color3}`}}
                                           className="te_reja">Kërkesat
                                            e Reja</p>
                                        <p onClick={() => setComponentToBeRendered('old')} style={{color: `${color1}`}}
                                           className="te_skaduara">Kërkesat e
                                            Skaduara</p>
                                        <p onClick={() => setComponentToBeRendered('done')} style={{color: `${color2}`}}
                                           className="te_realizuara">Kërkesat e
                                            Realizuara</p>
                                        <hr/>
                                    </div>
                                    {/*{uneditedTransactionList?.length > 8 ?*/}
                                    {/*    <div className="col-6 col-md-6 col-sm-12 col-xs-12 md-form mt-3">*/}
                                    {/*        <input*/}
                                    {/*            type="text"*/}
                                    {/*            onChange={searcher}*/}
                                    {/*            className="form-control"*/}
                                    {/*            placeholder="Search..."*/}
                                    {/*            aria-label="Search"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    : ''}*/}
                                    {/*{componentToBeRendered === 'new'   ?  <TeReja/>*/}
                                    {/*    : componentToBeRendered === 'old' ? <TeSkaduara/>*/}
                                    {/*        : componentToBeRendered === 'done' ? <TeRealizuara />*/}
                                    {/*            : ''*/}
                                    {/*}*/}
                                    {
                                        componentToBeRendered === 'new' ? <TeReja/> : ''
                                    }
                                    {
                                        componentToBeRendered === 'old' ? <TeSkaduara/> : ''
                                    }
                                    {
                                        componentToBeRendered === 'done' ? <TeRealizuara/> : ''
                                    }
                                        {/*This is is a kerkesat e reja*/}
                                </div>
                                {/*{uneditedTransactionList?.length > 8*/}
                                {/*    ?*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-12">*/}
                                {/*            <ReactPaginate*/}
                                {/*                previousLabel={"Previous"}*/}
                                {/*                nextLabel={"Next"}*/}
                                {/*                pageCount={Math.ceil(uneditedTransactionList.length / 8)}*/}
                                {/*                onPageChange={changePage}*/}
                                {/*                forcePage={pageNumber}*/}
                                {/*                containerClassName={"paginationBttns"}*/}
                                {/*                previousLinkClassName={"previousBttn"}*/}
                                {/*                nextLinkClassName={"nextBttn"}*/}
                                {/*                disabledClassName={"paginationDisabled"}*/}
                                {/*                activeClassName={"paginationActive"}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    ''}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BiznesShitjet;
