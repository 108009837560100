import React, { useState, useEffect } from 'react';

import axios from '../../axios';

// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";


const ShtoProdukt = () => {

  const [productData, addproductdata] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);
  const token = window.localStorage.getItem('cd');
  const decode = token ? jwt_decode(token) : null;


  let history = useHistory()

  const vleratBaze = () => {
    const ud = Object.assign({}, productData);
    ud.active = true;
    addproductdata(ud);
  }

  const emri = e => {
    const ud = Object.assign({}, productData);
    ud.name = e.target.value;
    addproductdata(ud);
  }

  const kategoria = e => {
    const ud = Object.assign({}, productData);
    ud.product_cat_id = e.target.value;
    addproductdata(ud);
  }

  const description = e => {
    const ud = Object.assign({}, productData);
    ud.details = e.target.value;
    addproductdata(ud);
  }
  const image = e => {

    // e.preventDefault();
    const ud = Object.assign({}, productData);
    ud.image = e.target.files[0];
    addproductdata(ud);
  }

  const shtoProduktin = (e) => {
    e.preventDefault();

    // console.log('data', productData);
    // console.log('tikeni', decode);
    // console.log('categories', categoriesData);

    let formDataa = new FormData();

    formDataa.append('name', e.target.name.value);
    formDataa.append('product_cat_id', e.target.product_cat_id.value);
    formDataa.append("image", e.target.pic_url.files[0]);
    formDataa.append('details', e.target.description.value);
    // formDataa.append("store_id", store_id);
    formDataa.append("active", true);


    axios.post('/furnitor/store-products/add/' + decode.store, formDataa)
      .then((res) => {
        toast.success('Produkti u shtua me sukses!');
        history.push( `/menaxhoproduktet`);
      })
      .catch(err => {
        window.localStorage.removeItem('cd');
        toast.error('Diçka shkoi gabim!');
        history.push("/kyqu");
        console.log(err);
      });


  }

  useEffect(() => {
    // getCategories();
    axios.get('/furnitor/product-categories/all')
      .then((res) => {
        console.log('axios', res);
        setCategoriesData(res.data);
      })
      .catch(err => { console.log(err); window.localStorage.removeItem('cd'); window.location = "/kyqu"; })
  }, [])



  return (
    <>
      <div className="shto_produkt">
            <div className="fjeshil-container">
              <div className="row">
                  <h3 className='w-100 p-3'> Shto Produkt</h3>
                <div className="col-md-12">
                  <div className="shp_groupform">
                    <form onSubmit={shtoProduktin}>
                      {categoriesData && categoriesData.map((el) => {
                        if (el._id == productData.product_cat_id) {
                          return <img src={process.env.REACT_APP_BACK_URL + el.pic_url} width={'150px'} alt='CategoryImage' />
                        }
                      })}
                      <input
                          name="name"
                        type="text"
                        className="form-control"
                        placeholder="Emri i Produktit"
                        onChange={emri}
                        required
                      />
                      <label>
                        <select onChange={kategoria} name="product_cat_id" className="form-control" >
                          <option value={null}>Selekto Kategorinë</option>
                          {categoriesData?.map(
                            (el, index) => {
                              return <option key={index} value={el._id}>{el.name}</option>
                            }
                          )}
                        </select>
                      </label>
                      <label>
                        <textarea
                            name="description"
                          className="rb_textarea"
                          rows={3}
                          cols={60}
                          placeholder="Përshkrimi..."
                          onChange={description}
                        ></textarea>
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Cmimi"
                        required
                      /> */}
                      <input
                        type="file"
                        name='pic_url'
                        className="form-control"
                        placeholder="picture"
                        onChange={image}
                      />
                      {/* <Link to="/menaxhoproduktet"> */}
                      <button type="submit">Shto </button>
                      {/* </Link> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default ShtoProdukt;
