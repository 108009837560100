import './ListViewStock.scss'
import {Link} from "react-router-dom";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";
import Reports from '../../../images/chart-bar-regular.svg';
import React from "react";

const ListViewStock = (props) => {

    console.log('', props)

    return (
        <>
            <tbody>
            <tr>
                <td className="col-sm">{props?.data?.prodData[0]?.name} </td>
                <td className="col-sm"> {props?.data.qty}</td>
                {/*<div className="col-sm"> {props?.data?.active ? "Active" : "Passive"}</div>*/}
                <td className="col-sm"> {props?.data.price}</td>
                <td className="col-sm"> {props?.data.expiration_date.slice(0, 10)}</td>
                <td className='col-sm'>
                    <Link to={'/editostokun/' + props?.data._id}>
                        <img
                            alt="delete"
                            src={EditIcon}
                            className='actions'
                        />
                    </Link>
                </td>
                <td className='col-sm'>
                    <Link to={'/fshijstokun/' + props.data._id}>
                        <img src={DeleteIcon} alt="delete" className='actions'/>
                    </Link>
                </td>
            </tr>
            </tbody>
        </>
    )
}

export default ListViewStock;