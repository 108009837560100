import './ListView.scss'
import React from "react";
import {Link} from "react-router-dom";

const ListView = (props) => {

    let {filtered, date, acceptOrder, declineOrder} = props

    console.log('props incoming function', props)

    return (
        <div>
            <div className="List">
                {/*<div className="row">*/}
                <div className={'listimi-transactions'}>
                    <div className="row pl-1 ">

                        <div className="col-sm-2"
                             style={{
                                 width: '300px',
                                 overflow: 'hidden',
                                 whiteSpace: 'nowrap',
                                 textOverflow: 'ellipsis',
                             }}>
                            {filtered.product_name}
                        </div>
                        <div className="col-sm-2">
                            {filtered.user_name}
                        </div>
                        <div className="col-sm-3">{filtered.qty} {filtered.messurment_name}</div>
                        <div className="col-sm">{filtered.total_price}</div>
                        <div className="col-sm ">{date}</div>
                        {
                            filtered?.status === 'initial'
                                ?
                                <div className='list-view'>
                                    <div className="col-sm ">
                                        <Link to="/" >
                                            <span
                                                onClick={() => acceptOrder(filtered._id)}
                                                className="prano">Prano
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="col-sm">
                                        <Link to="/" >
                                            <span
                                                onClick={() => declineOrder(filtered._id)}
                                                className="anulo">Anulo
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListView
