import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
} from 'react-router-dom';
import Kycu from './components/Kycu/Kycu';
import BiznesProduktet from './components/BiznesProduktet/BiznesProduktet';
import BiznesShitjet from './components/BiznesShitjet/BiznesShitjet';
import BiznesStoku from './components/BiznesStoku/BiznesStoku';
import ShtoProdukt from './components/ShtoProdukt/ShtoProdukt';
import EditoProdukt from './components/ShtoProdukt/EditoProdukt';
import FshijProdukt from './components/ShtoProdukt/FshijProdukt';
import ShtoStokun from './components/ShtoStokun/ShtoStokun';
import EditoStokun from './components/ShtoStokun/EditoStokun';
import FshijStokun from './components/ShtoStokun/FshijStokun';
import BiznesFaqja from './components/BiznesFaqja/BiznesFaqja';
import BiznesShfrytezuesi from './components/BiznesShfrytezuesi/BiznesShfrytezuesi';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
// import TeSkaduara from './components/TeSkaduara/TeSkaduara';
// import TeRealizuara from './components/TeRealizuara/TeRealizuara';

// import EditoProdukt from './components/ShtoProdukt/EditoProdukt';
import Auth from './Auth';
import BiznesSidebar from './components/BiznesSidebar/BiznesSidebar';
import './App.scss';
import Logo from './images/newfoodsafe.png';
import StatsProdukt from "./components/ShtoProdukt/StatsProdukt";
import MobileSidebar from "./components/MobileSidebar/MobileSidebar";
import Transactions from "./components/Transactions/Transactions";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Statistikat from "./components/Statistikat/Statistikat";
// const token = window.localStorage.getItem('cd');
// const role = token ? jwt_decode(token).role : null;
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobileMenu from "./components/MobileSidebar/mobilemenu";

function App() {


    console.log('is mobile this', isMobile)

    return (
        <>
            <div className="container-fluid">
                <ToastContainer/>
                <Router>
                    <div className='fjeshil-logo '>
                        <img src={Logo} alt="logo"/>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-12 mobile-container">
                            <div className="mobile-lel">
                                {Auth() && isMobile ? <MobileMenu/> : ''}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='fjeshil-sidebar'>
                            {Auth() && isBrowser ? <BiznesSidebar/> : ''}
                        </div>
                        <div className='fjeshil-content bg-light'>
                            {Auth() ? (
                                <Switch>
                                    <Route exact path="/" component={BiznesShitjet}/>
                                    <Route exact path="/menaxhoproduktet" component={BiznesProduktet}/>
                                    <Route exact path="/biznesstoku" component={BiznesStoku}/>
                                    <Route exact path="/shtoprodukt" component={ShtoProdukt}/>
                                    <Route exact path="/editoprodukt/:id" component={EditoProdukt}/>
                                    <Route exact path="/fshijprodukt/:id" component={FshijProdukt}/>
                                    <Route exact path="/shtostokun" component={ShtoStokun}/>
                                    <Route exact path="/editostokun/:id" component={EditoStokun}/>
                                    <Route exact path="/fshijstokun/:id" component={FshijStokun}/>
                                    <Route exact path="/dyqani" component={BiznesFaqja}/>
                                    <Route exact path="/transactions" component={Transactions}/>
                                    <Route exact path="/shfrytezuesi" component={BiznesShfrytezuesi}/>
                                    <Route exact path="/singleproduct/:id" component={StatsProdukt}/>
                                    <Route exact path="/statistikat" component={Statistikat}/>
                                    {/*<Route exact path="/forgot-password" component={ForgotPassword}/>*/}
                                    {/*<Route exact path="/skaduara" component={TeSkaduara}/>*/}
                                    {/*<Route exact path="/realizuara" component={TeRealizuara}/>*/}
                                    {/* <Route exact path="/editoprodukt/:id" component={EditoProdukt} /> */}
                                </Switch>
                            ) : (
                                <>
                                    <Redirect exact to="/kyqu"/>
                                    <Route exact path="/kyqu" component={Kycu}/>
                                    <Route exact path="/forgot-password" component={ForgotPassword}/>
                                </>
                            )}
                        </div>
                    </div>
                </Router>
            </div>
        </>
    );
}

export default App;
