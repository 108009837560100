import React, {useState} from 'react';
import {Link} from 'react-router-dom';

// Components
import Footer from '../../components/Footer/Footer';

// Import Images
import Logo from '../../images/newfoodsafe.png';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
// CSS
import './Kycu.scss';
import axios from './../../axios.js';

const Kycu = () => {

    let history = useHistory()

    const [userData, adduserdata] = useState({});
    const [errors, addErrors] = useState(false);

    const username = e => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        adduserdata(ud);
    }

    const password = e => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        adduserdata(ud);
    }


    let errorLoginMsg = ''

    const submit = e => {
        e.preventDefault();
        if (userData.email && userData.pass) {
            axios.post('/furnitor/login', userData)
                .then((res) => {
                    if (res.data.token) {
                        addErrors(false);
                        window.localStorage.setItem('cd', res.data.token);
                        window.location = "/";
                    } else {
                        addErrors(res.data.error);
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error('Diçka shkoi gabim!');
                    // window.localStorage.removeItem('cd');
                    // window.location = "/kyqu";
                })
        } else {
            addErrors(true);
        }
    }


    // console.log(errors)


    return (
        <>
            <div className="login">
                <div className="container">
                    <div className="row">
                        {/* Logo */}
                        <div className="col-md-12">
                            {/*<Link to="/">*/}
                            <div className="logo_login">
                                <img src={Logo} alt="logo"/>
                            </div>
                            {/*</Link>*/}
                        </div>
                        {/* Form */}
                        <div className="col-md-12">
                            <form onSubmit={submit}>
                                <div className="login_formgroup">
                                    <div className="input-container">
                                        <i className="fa fa-user icon"></i>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="your email"
                                            onChange={username}
                                            required
                                        />
                                    </div>
                                    <div className="input-container">
                                        <i className="fa fa-user icon"></i>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="password"
                                            onChange={password}
                                            required
                                        />
                                    </div>
                                    <div className="btn_signin">
                                        {/* <Link to="/menaxhoblerjetshitjet"> */}
                                        <button type="submit">Kyçu</button>
                                        {/* </Link> */}

                                    </div>
                                    <div className="forgot-password">
                                        <Link to="/forgot-password">
                                            <p className="forgot-password-part">Ndrysho Fjalëkalimin</p>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <span className="error-msg">{errors}</span>
                </div>
                {/* Footer */}
            </div>
            <Footer/>
        </>
    );
};

export default Kycu;
