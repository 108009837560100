import React, {useState, useEffect} from 'react';
import axios from '../../axios';

// CSS
import './ShtoProdukt.scss';
import jwt_decode from 'jwt-decode';
import * as queryString from "querystring";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";

const ShtoProdukt = (props) => {

    const [productData, addproductdata] = useState({});
    const [categoriesData, setCategoriesData] = useState([]);
    const [imageData, setimageData] = useState();
    const [store_id, setStore_id] = useState();


    const token = window.localStorage.getItem('cd');
    const decode = token ? jwt_decode(token) : null;

    let formData = new FormData();


    let history = useHistory();

    const vleratBaze = () => {
        const ud = Object.assign({}, productData);
        ud.active = true;
        addproductdata(ud);
        formData.append('active', ud.active)
    }
    const emri = e => {
        const ud = Object.assign({}, productData);
        ud.name = e.target.value;
        addproductdata(ud);
        formData.append('name', ud.name)
    }

    const kategoria = e => {
        const ud = Object.assign({}, productData);
        ud.product_cat_id = e.target.value;
        addproductdata(ud);
        formData.append('product_cat_id', ud.product_cat_id)
    }

    const description = e => {
        const ud = Object.assign({}, productData);
        ud.details = e.target.value;
        addproductdata(ud);
        formData.append('details', ud.details)
    }


    const image = async e => {
        let image = e.target.files[0];
        // e.preventDefault();


        const ud = Object.assign({}, productData);
        ud.pic_url = formData;
        await addproductdata(ud);
        await setimageData(image);
        formData.append('pic_url', imageData)
    }


    const editoProduktin = (e) => {
        e.preventDefault();

        let formDataa = new FormData();

        formDataa.append('name', e.target.name.value);
        formDataa.append('product_cat_id', e.target.product_cat_id.value);
        formDataa.append("image", e.target.pic_url.files[0]);
        formDataa.append('details', e.target.description.value);
        formDataa.append("store_id", store_id);
        formDataa.append("active", true);
        for (let [key, value] of formDataa) {
            console.log(`${key}: ${value}`)
        }
        axios.post('/furnitor/store-products/update/' + props.match.params.id, formDataa)
            .then((res) => {
                console.log(res.data);
                toast.success('Produkti u ndryshua me sukses!');
                history.push(`/menaxhoproduktet`);
            })
            .catch(err => {
                window.localStorage.removeItem('cd');
                history.push("/kyqu");
                toast.error('Diçka shkoi gabim!');
                console.log(err);
            });
    }

    useEffect(() => {

        axios.get('/furnitor/product-categories/all')
            .then((res) => {
                console.log('axios', res);
                setCategoriesData(res.data);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                history.push("/kyqu");
            });


        axios.get('/furnitor/store-products/' + props.match.params.id)
            .then((res) => {
                console.log('produkti', res.data.name);
                addproductdata(res.data);
                setStore_id(res.data.store_id);
            })
            .catch(err => {
                console.log(err);
                window.localStorage.removeItem('cd');
                history.push("/kyqu");
            });

    }, [])


    // const ruajImazhin = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append('image', imageData.image)
    //     const config = {
    //         headers: {
    //             'content-type': 'multipart/form-data',
    //             'Access-Control-Allow-Origin': '*'
    //         }
    //     }
    //     axios.post("/furnitor/user/update-image/" + decode.id, formData, config)
    //         .then((res) => {
    //             console.log(res.data);
    //             // console.log('image',formData)
    //         })
    //         .catch((err) => console.log(err));
    //     // getStore();
    //     // window.location.reload();
    // }


    return (
        <>
            <div className="shto_produkt">
                <div className="fjeshil-container">
                    <div className="row">
                        <h3 className='w-100 p-3'> Edito Produktin</h3>
                        {/* <h5 className='w-100 p-3'>{productData.name}</h5> */}
                        <div className="col-md-12">
                            <div className="shp_groupform">
                                <form onSubmit={editoProduktin}>
                                    {productData?.pic_url == null ?
                                        (categoriesData && categoriesData.map((el) => {
                                            if (el._id == productData.product_cat_id) {
                                                return <img src={process.env.REACT_APP_BACK_URL + el.pic_url}
                                                            width={'150px'} alt='CategoryImage'/>
                                            }
                                        })) :
                                        <img src={process.env.REACT_APP_BACK_URL + productData.pic_url} width="200px"
                                             className="pb-2 " alt='productPic'/>}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Emri i Produktit"
                                        defaultValue={productData.name}
                                        onChange={emri}
                                        name="name"
                                        required
                                    />
                                    <label>
                                        <select name="product_cat_id" onChange={kategoria}
                                                value={productData.product_cat_id}
                                                className="form-control">
                                            <option value={null}>Selekto</option>
                                            {categoriesData?.map(
                                                (el, index) => {
                                                    return <option key={index} value={el._id}>{el.name}</option>
                                                }
                                            )}
                                        </select>
                                    </label>
                                    <label>
                        <textarea
                            name="description"
                            className="rb_textarea"
                            rows={3}
                            cols={60}
                            placeholder="   Description..."
                            defaultValue={productData.details}
                            onChange={description}
                        ></textarea>
                                    </label>
                                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Cmimi"
                        required
                      /> */}

                                    <input
                                        type="file"
                                        name='pic_url'
                                        id='file'
                                        className="form-control"
                                        placeholder="picture"
                                        onChange={image}

                                    />
                                    {/* <Link to="/menaxhoproduktet"> */}
                                    <button type="submit">Edito</button>
                                    {/* </Link> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShtoProdukt;
