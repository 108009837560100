import React, {useEffect, useState} from 'react';
import './Footer.scss';
import {Link, NavLink} from "react-router-dom";
import axios from "../../axios";
import {toast} from "react-toastify";

const Footer = () => {

  const [emails, setEmails] = useState([]);


  const getEmails = () => {
    axios.get('/general/social-media')
        .then((data) => {
          console.log(data);
          setEmails(data.data);
        })
        .catch(err => {
          console.log(err);
          toast.error('Diçka shkoi gabim!');
        });
  }


  useEffect(() => {
    getEmails()
  }, [])

  return (
      <div className="footer">
        <footer>
          <div className="footer_links">
            <ul>
              <li><a target="_blank" href={`https://${emails?.facebook_url}`}>Facebook</a></li>
              <li><a target="_blank" href={`https://${emails?.instagram_url}`}>Instagram</a></li>
              <li><a target="_blank" href={`https://${emails?.twitter_url}`}>Twitter</a></li>
              <li><a target="_blank" href={`https://${emails?.linkedin_url}`}>Linkedin</a></li>
            </ul>
          </div>
          <div className="footer_desc">
            <p>
              {emails?.contact_email} | {emails?.phone} | {emails?.contact_address}
            </p>
          </div>
        </footer>
      </div>
  );
};

export default Footer;
